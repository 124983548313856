import React from "react";

import { sampleStyles } from "../../../pages/Report/Styles";

import { View, Text } from "@react-pdf/renderer";

const SampleReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={sampleStyles.firstContainer} wrap={false}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Nº UE: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Material: {report.material}
          </Text>
        </View>
      </View>
      <Text style={sampleStyles.margin}>Descrição: {report.descrição}</Text>
      <Text style={sampleStyles.margin}>
        Material: {report.material_amostra}
      </Text>
      <View style={sampleStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Século: {report.século} </Text>
        </View>
      </View>
      <View style={sampleStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>X: {report.x}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Y: {report.y} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Z: {report.z} </Text>
        </View>
      </View>
    </>
  );
};

export default SampleReport;
