import { StyleSheet } from "@react-pdf/renderer";

// SEDIMENTAR
export const sedimentaryStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});
// CONSTRUTIVA
export const constructiveStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  margin: {
    marginTop: "10px",
    marginBottom: "10px",
  },
});

// INTERFACE
export const interfaceStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  margin: {
    marginTop: "10px",
    marginBottom: "10px",
  },
});

// TABLE
export const table = StyleSheet.create({
  normalCell: {
    fontSize: "10px",
    textAlign: "center",
  },
  cell: {
    fontSize: "8px",
    fontFamily: "Courier-Bold",
    textAlign: "center",
    overflow: "hidden",
  },
});

// CERÂMICA
export const ceramicStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});

// LÍTICO
export const lithicStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});

// VIDRO
export const glassStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});

// MOEDA
export const coinStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});

// AMOSTRA
export const sampleStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});

// METAL
export const metalStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});

// OBJETO DIVERSO
export const diverseStyles = StyleSheet.create({
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "justify",
  },
});
