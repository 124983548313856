import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../context/AuthContext";
import { CircularProgress } from "@material-ui/core";
import { columns } from "../../components/customs/Table/Headers/PollColumns";
import { ueColumns } from "../../components/customs/Table/Headers/UeColumns";
import { materialColumns } from "../../components/customs/Table/Headers/MaterialColumns";

import useSWR from "swr";

import PollTable from "./PollTable";
import UeTable from "./UeTable";

import InterventionData from "./InterventionData";
import InterventionList from "./InterventionList";

import MaterialTable from "../../components/Material/MaterialTable";

import "./ViewIntervention.css";

const ViewIntervention = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { page } = location?.state || "polls";

  const { id: userId } = useContext(Context);
  const { data: intervention } = useSWR(
    () => `/api/intervention/${userId}/${id}`
  );
  const { data: polls, mutate: mutatePolls } = useSWR(
    () => `/api/polls/${userId}/${intervention[0]?.id_sítio}`
  );
  const { data: ues, mutate: ueMutate } = useSWR(`/api/intervention/ues/${id}`);
  const { data: materials, mutate: materialMutate } = useSWR(
    () => `/api/material/intervention/${id}`
  );

  // por defeito sondagens ficam ativas
  const [checkbox, setCheckbox] = useState({
    polls: true,
    ues: false,
    materials: false,
  });

  useEffect(() => {
    setCheckbox({
      polls: false,

      ues: false,
      materials: false,
      [page || "polls"]: true,
    });
  }, [page]);

  const handleChange = (e) => {
    setCheckbox({
      polls: false,
      ues: false,
      materials: false,
      [e.target.name]: true,
    });
  };

  const newPoll = () => {
    const { sítio, id_sítio } = intervention[0];

    navigate("/sondagens/nova", {
      state: {
        sítio: sítio,
        id_sítio: id_sítio,
        id_intervenção: id,
      },
    });
  };

  const backToInterventions = () => {
    navigate("/intervenções");
  };

  if (!polls) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <div className="container">
      <div className="title-container">
        <h1>Intervenção</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToInterventions}
        />
      </div>
      {!intervention ? (
        <CircularProgress
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15vh",
            width: "50px",
            height: "50px",
          }}
        />
      ) : (
        intervention.length && (
          <>
            <InterventionData intervention={intervention} />
            <InterventionList checkbox={checkbox} handleChange={handleChange} />
            {checkbox.polls && (
              <PollTable
                polls={polls}
                newPoll={newPoll}
                id={id}
                mutatePolls={mutatePolls}
                columns={columns}
              />
            )}
            {checkbox.ues && (
              <UeTable
                ues={ues}
                id={id}
                ueMutate={ueMutate}
                ueColumns={ueColumns}
              />
            )}
            {checkbox.materials && (
              <MaterialTable
                materials={materials}
                id={id}
                materialMutate={materialMutate}
                materialColumns={materialColumns}
                sítio={intervention[0].sítio}
              />
            )}
          </>
        )
      )}
    </div>
  );
};

export default ViewIntervention;
