import React, { useState } from "react";

import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncDebounce } from "react-table";

import "./Filter.css";

const Filter = (props) => {
  const { filter, setFilter } = props;
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 150);

  return (
    <div className="filter-input-wrapper">
      <input
        className="filter-input-input"
        type="text"
        placeholder="procurar..."
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      <FontAwesomeIcon className="input-icon" icon="search" size="sm" />
    </div>
  );
};

Filter.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

export default Filter;
