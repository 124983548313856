import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";
import { axios } from "../../../api/axios";

const EditMatrix = (props) => {
  const { data, setNewUE } = props;
  const { data: matrix } = useSWR("/api/ue/sedimentary/matrix");

  const onSelectType = (selectedList, selectedItem) => {
    setNewUE((prev) => ({ ...prev, matriz: selectedItem.id_matriz }));
  };

  if (!matrix) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const matrixValue =
    matrix &&
    matrix.map((value) => {
      return [
        {
          id_matriz: value.id_matriz,
          matriz: value.matriz,
        },
      ];
    });

  const matrix_id = matrixValue.findIndex(
    (obj) => obj[0].id_matriz === data.matriz
  );

  return (
    <>
      <h3 className="ue-h3">Matriz:</h3>
      <Multiselect
        options={matrix}
        selectedValues={matrixValue[matrix_id]}
        singleSelect
        showArrow
        onSelect={onSelectType}
        hidePlaceholder
        displayValue="matriz"
        placeholder="matriz"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas matrizes"
      />
    </>
  );
};

export default EditMatrix;
