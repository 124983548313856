export const ueValues = {
  sondagem: 0,
  ue: "",
  caracterização: 0,
  sobreposta: -1,
  corta: -1,
  cortada: -1,
  preenche: -1,
  preenchida: -1,
  encosta: -1,
  igual: -1,
  data: "",
  intervenção: -1,
  created: new Date().toLocaleString(),
  role: "user",
};

export const sedimentaryValues = {
  cor: "",
  matriz: null,
  compacidade: null,
  descrição: "",
  interpretação: "",
  cronologia: null,
  inclusões: [],
};

export const constructiveValues = {
  tipo: null,
  aparelho: null,
  material: null,
  comprimento: "",
  largura: "",
  espessura: "",
  descrição: "",
  interpretação: "",
  cronologia: null,
  inclusões: [],
};

export const interfaceValues = {
  descrição: "",
  interpretação: "",
  cronologia: null,
};
