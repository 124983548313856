import React from "react";

import InterfaceData from "../Interface/interfaceData";
import Compactness from "./compactness";
import Inclusions from "./inclusions";
import Matrix from "./matrix";

import "./Sedimentary.css";

const Sedimentar = (props) => {
  const { setSedimentary } = props;

  const handleChange = (e) => {
    setSedimentary((prev) => ({ ...prev, cor: e.target.value }));
  };

  return (
    <div>
      <hr />
      <h2>Sedimentar:</h2>
      <div className="sedimentary-container">
        <div className="inclusions border flex">
          <Inclusions {...props} />
        </div>
        <div className="color border flex">
          <h3 className="ue-h3">Cor:</h3>
          <input
            className="ue-input"
            type="text"
            name="cor"
            onChange={handleChange}
          />
        </div>
        <div className="matrix border flex">
          <Matrix setSedimentary={setSedimentary} />
        </div>
        <div className="compactness border flex">
          <Compactness setSedimentary={setSedimentary} />
        </div>
      </div>
      <InterfaceData type="sedimentary" setSedimentary={setSedimentary} />
    </div>
  );
};

export default Sedimentar;
