export const SidebarData = [
  {
    id: 1,
    title: "Perfil",
    path: "/perfil",
    icon: "user-alt",
    iconClosed: "sort-down",
    iconOpened: "sort-up",
    subNav: [
      {
        role: "admin",
        title: "Novo Utilizador",
        path: "/perfil/novo",
        icon: "plus",
      },
      {
        role: "admin",
        title: "Listar Utilizadores",
        path: "/perfis",
        icon: "list",
      },
    ],
  },
  {
    id: 2,
    title: "Intervenções",
    path: "/intervenções",
    icon: "map-marked-alt",
    iconClosed: "sort-down",
    iconOpened: "sort-up",
    subNav: [
      {
        role: "admin",
        title: "Abrir Intervenção",
        path: "/intervenções/nova",
        icon: "plus",
      },
    ],
  },
];
