import React from "react";

import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import history from "./utils/history";

import Routes from "./routes/Routes";

import { SWRConfig } from "swr";
import { axios } from "./api/axios";

import { ToastContainer } from "react-toastify";

import "./components/Font/index";

import "./App.css";

const fetcher = (url) => axios.get(url).then((response) => response.data);

function App() {
  return (
    <AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HistoryRouter history={history}>
        <SWRConfig value={{ fetcher }}>
          <Routes />
        </SWRConfig>
      </HistoryRouter>
    </AuthProvider>
  );
}

export default App;
