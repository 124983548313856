import React from "react";

import { Navigate } from "react-router-dom";

import { useAuthContext } from "../context/AuthContext";

const ProtectedRoute = (props) => {
  const { isPrivate, Component } = props;
  const { authenticated, cookies } = useAuthContext();

  return isPrivate && !authenticated ? (
    <Navigate to="/" exact />
  ) : (
    <Component />
  );
};

export default ProtectedRoute;
