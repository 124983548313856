export const columns = [
  {
    Header: "Nº",
    accessor: "id",
  },
  {
    Header: "Nome",
    accessor: "nome",
  },
  {
    Header: "User",
    accessor: "id_user",
  },
  {
    Header: "Utilizador",
    accessor: "username",
  },
];
