import React from "react";

import Multiselect from "multiselect-react-dropdown";

import { CircularProgress } from "@material-ui/core";

import useSWR from "swr";

const UE = (props) => {
  const { sítio, setId, setUe } = props;
  const { data } = useSWR("/api/caracterization");

  const onSelectType = (selectedList, selectedItem) => {
    setId(selectedItem.id);
    setUe((prev) => ({ ...prev, caracterização: selectedItem.id }));
  };

  const handleChange = (e) => {
    setUe((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <div className="ue-grid-container">
      <div className="place border flex">
        <h3 className="ue-h3">Sítio:</h3>
        <h3 className="ue-h3" style={{ marginLeft: "10px" }}>
          {sítio}
        </h3>
      </div>
      <div className="acronym border flex">
        <h3 className="ue-h3">UE:</h3>
        <input
          className="ue-input"
          type="text"
          name="ue"
          onChange={handleChange}
        />
      </div>
      <div className="caracterization border flex">
        <h3 className="ue-h3">Caracterização:</h3>
        <Multiselect
          className="ue-input"
          options={data}
          selectedValues={data.selectedValue}
          singleSelect
          showArrow
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="caracterização"
          placeholder="Caracterização"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas caracterizações"
        />
      </div>
    </div>
  );
};

export default UE;
