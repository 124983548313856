import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditMaterial = (props) => {
  const { data, setNewMaterial } = props;
  const { data: materials } = useSWR("/api/material/lithic/materials");

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectMaterials = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, material_litico: selectedItem.id }));
  };

  const materialValue =
    materials &&
    materials.map((value) => {
      return [
        {
          id_material: value.id,
          material: value.material,
        },
      ];
    });

  const material_id = materialValue.findIndex(
    (obj) => obj[0].id_material === data.material_litico
  );

  return (
    <>
      <h3 className="ue-h3">Material:</h3>
      <Multiselect
        options={materials}
        selectedValues={materialValue[material_id]}
        singleSelect
        onSelect={onSelectMaterials}
        hidePlaceholder
        displayValue="material"
        placeholder="Material"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados materiais com esse nome"
      />
    </>
  );
};

export default EditMaterial;
