import React, { useState, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Context } from "../../context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Material from "../../components/Material/Create/Material";
import UEs from "../../components/Material/Create/UEs";

import Ceramics from "../Materials/Ceramics/index";
import Lithic from "../Materials/Lithic/index";
import Glass from "../Materials/Glass/index";
import Metal from "../Materials/Metal/index";
import Coin from "../Materials/Coin/index";
import Diverse from "../Materials/Diverse/index";
import Sample from "../Materials/Sample/index";

import {
  materialValues,
  ceramicsValues,
  lithicValues,
  glassValues,
  metalValues,
  coinValues,
  diverseValues,
  sampleValues,
} from "../../components/Material/Create/MaterialData";

import { CustomToast } from "../../components/customs/Toast/Toast";

import { axios } from "../../api/axios";

import "./Material.css";

const CreateMaterialPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { intervenção, sítio } = location?.state;
  const { id } = useContext(Context);

  const [interventionID, setInterventionID] = useState(0);
  const [materialName, setMaterialName] = useState("");

  // tipos material
  const [material, setMaterial] = useState(materialValues);
  const [ceramics, setCeramics] = useState(ceramicsValues);
  const [lithic, setLithic] = useState(lithicValues);
  const [glass, setGlass] = useState(glassValues);
  const [metal, setMetal] = useState(metalValues);
  const [coin, setCoin] = useState(coinValues);
  const [diverse, setDiverse] = useState(diverseValues);
  const [sample, setSample] = useState(sampleValues);

  const createMaterial = () => {
    material.utilizador = id;
    material.intervenção = ~~intervenção;

    let data;
    switch (materialName) {
      case "Cerâmica":
        data = { ...material, ...ceramics };
        break;
      case "Lítico":
        data = { ...material, ...lithic };
        break;
      case "Vidro":
        data = { ...material, ...glass };
        break;
      case "Metal":
        data = { ...material, ...metal };
        break;
      case "Moeda":
        data = { ...material, ...coin };
        break;
      case "Objeto Diverso":
        data = { ...material, ...diverse };
        break;
      case "Amostra":
        data = { ...material, ...sample };
        break;
      default:
    }

    if (!!data.fragmentos && isNaN(data.fragmentos)) {
      const { msg, error } = {
        msg: "Nº de fragmentos tem de ser um número",
        error: true,
      };
      CustomToast(error, msg);
      return;
    }

    axios
      .post("/api/material", data)
      .then((response) => {
        const { msg, error } = response.data;
        CustomToast(error, msg);

        if (!error) {
          navigate(`/intervenções/${intervenção}`, {
            state: {
              interventionID: interventionID,
              page: "materials",
            },
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const backToMaterials = () => {
    navigate(`/intervenções/${intervenção}`, {
      state: {
        interventionID: intervenção,
        page: "materials",
      },
    });
  };

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">Novo Material</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToMaterials}
        />
      </div>
      <div className="material-container">
        <div className="intervention border flex">
          <h3 className="ue-h3">Intervenção: {sítio} </h3>
        </div>
        <div className="UE flex border">
          <UEs
            interventionID={intervenção}
            setMaterial={setMaterial}
            setMaterialName={setMaterialName}
          />
        </div>
        <div className="material flex border">
          <Material
            setMaterial={setMaterial}
            material={material}
            setMaterialName={setMaterialName}
          />
        </div>
      </div>
      {materialName === "Cerâmica" && <Ceramics setCeramics={setCeramics} />}
      {materialName === "Lítico" && <Lithic setLithic={setLithic} />}
      {materialName === "Vidro" && <Glass setGlass={setGlass} />}
      {materialName === "Metal" && <Metal setMetal={setMetal} />}
      {materialName === "Moeda" && <Coin setCoin={setCoin} />}
      {materialName === "Objeto Diverso" && <Diverse setDiverse={setDiverse} />}
      {materialName === "Amostra" && <Sample setSample={setSample} />}
      {materialName !== "" && (
        <button className="create-ue-btn" onClick={createMaterial}>
          Guardar
        </button>
      )}
    </div>
  );
};

export default CreateMaterialPage;
