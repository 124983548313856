import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const Cronology = (props) => {
  const { type, setInterface, setConstructive, setSedimentary } = props;
  const { data: cronology } = useSWR("/api/ue/cronology");

  const onSelectCronology = (selectedList, selectedItem) => {
    if (type === "sedimentary") {
      setSedimentary((prev) => ({
        ...prev,
        cronologia: selectedItem.id_cronologia,
      }));
    } else if (type === "constructive") {
      setConstructive((prev) => ({
        ...prev,
        cronologia: selectedItem.id_cronologia,
      }));
    } else {
      setInterface((prev) => ({
        ...prev,
        cronologia: selectedItem.id_cronologia,
      }));
    }
  };

  if (!cronology) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <>
      <h3 className="ue-h3">Cronologia:</h3>
      <Multiselect
        className="matrix-input"
        options={cronology}
        singleSelect
        showArrow
        onSelect={onSelectCronology}
        hidePlaceholder
        displayValue="cronologia"
        placeholder="cronologia"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas interpretações"
      />
    </>
  );
};

export default Cronology;
