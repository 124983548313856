import React, { useState, useEffect, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Context } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UE from "../../components/UE/UE";
import Sedimentar from "../../components/UE/Sedimentary/index";
import Construtiva from "../../components/UE/Constructive/index";
import Interface from "../../components/UE/Interface/index";
import Matrix from "../../components/UE/Matrix/index";

import {
  ueValues,
  sedimentaryValues,
  constructiveValues,
  interfaceValues,
} from "../../components/UE/ueData";

import { CustomToast } from "../../components/customs/Toast/Toast";
import { axios } from "../../api/axios";

import "./Ue.css";

const CreateUePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id_sondagem, sítio, id_intervenção } = location?.state;
  const { id: role } = useContext(Context);

  // data da UE
  const [ue, setUe] = useState(ueValues);
  const [sedimentary, setSedimentary] = useState(sedimentaryValues);
  const [constructive, setConstructive] = useState(constructiveValues);
  const [interfaces, setInterface] = useState(interfaceValues);
  const [id, setId] = useState(0);

  const createUE = () => {
    ue.sondagem = id_sondagem;
    ue.role = role;
    ue.data = new Date().toLocaleString();
    ue.intervenção = id_intervenção;

    let data;
    switch (id) {
      case 1:
        data = { ...ue, ...sedimentary };
        break;
      case 2:
        data = { ...ue, ...constructive };
        break;
      case 3:
      case 4:
        data = { ...ue, ...interfaces };
        break;
      default:
    }
    axios
      .post("/api/ue", data)
      .then((response) => {
        const { msg, error } = response.data;
        CustomToast(error, msg);

        if (!error) {
          navigate(`/sondagens/${id_sondagem}`, {
            state: { id: id_intervenção },
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const backToPolls = () => {
    navigate(`/sondagens/${id_sondagem}`, { state: { id: id_intervenção } });
  };

  useEffect(() => {
    ue.sondagem = id_sondagem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_sondagem]);

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">FICHA DE UE</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToPolls}
        />
      </div>
      <UE sítio={sítio} setId={setId} setUe={setUe} />
      {id === 1 && <Sedimentar setSedimentary={setSedimentary} id={id} />}
      {id === 2 && <Construtiva setConstructive={setConstructive} id={id} />}
      {(id === 3 || id === 4) && <Interface setInterface={setInterface} />}
      {id !== 0 && (
        <>
          <Matrix
            id_sondagem={id_sondagem}
            setUe={setUe}
            id_intervenção={id_intervenção}
          />
          <button className="create-ue-btn" onClick={createUE}>
            Guardar
          </button>
        </>
      )}
    </div>
  );
};

export default CreateUePage;
