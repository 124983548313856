import React, { useContext, useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Context } from "../../context/AuthContext";

import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomToast } from "../../components/customs/Toast/Toast";

import EditType from "../../components/Intervention/Edit/EditType";
import EditClassification from "../../components/Intervention/Edit/EditClassification";
import EditInterventionData from "../../components/Intervention/Edit/EditInterventionData";
import EditUsers from "../../components/Intervention/Edit/EditUsers";

import useSWR from "swr";
import { axios } from "../../api/axios";

import "./ViewIntervention.css";

const EditInterventionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { id: userId } = useContext(Context);
  const { data, mutate } = useSWR(
    () => `/api/intervention/edit/${userId}/${id}`
  );
  const [newIntervention, setNewIntervention] = useState({});

  const backToInterventions = () => {
    navigate("/intervenções");
  };

  console.log(data);

  useEffect(() => {
    if (data) {
      setNewIntervention(data[0]);
    }
  }, [data]);

  const editIntervention = () => {
    axios
      .put(`/api/intervention/${userId}/${id}`, {
        ...newIntervention,
        edited: new Date().toLocaleString(),
      })
      .then((response) => {
        // toast
        const { msg, error } = response.data;
        CustomToast(error, msg);
        mutate();
        if (!error) {
          navigate(`/intervenções/${id}`);
        }
      })
      .catch((error) => console.log(error));
  };

  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <div className="container">
      <div className="title-container">
        <h1>Editar Intervenção</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToInterventions}
        />
      </div>
      <h3>Tipo</h3>
      <EditType data={data[0]} setNewIntervention={setNewIntervention} />
      <h3>Classificação</h3>
      <EditClassification
        data={data[0]}
        setNewIntervention={setNewIntervention}
      />
      <EditInterventionData
        newIntervention={newIntervention}
        setNewIntervention={setNewIntervention}
      />
      <EditUsers data={data[0]} setNewIntervention={setNewIntervention} />
      <button className="create-ue-btn" onClick={editIntervention}>
        Guardar
      </button>
    </div>
  );
};

export default EditInterventionPage;
