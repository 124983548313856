import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditCategory = (props) => {
  const { data, setNewMaterial } = props;
  const { data: categories } = useSWR("/api/material/ceramics/categories");

  if (!categories) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectCategory = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, categoria: selectedItem.id }));
  };

  const categoryValue =
    categories &&
    categories.map((value) => {
      return [
        {
          id_categoria: value.id,
          categoria: value.categoria,
        },
      ];
    });

  const category_id = categoryValue.findIndex(
    (obj) => obj[0].id_categoria === data.categoria
  );

  return (
    <>
      <h3 className="ue-h3">Categoria:</h3>
      <Multiselect
        options={categories}
        selectedValues={categoryValue[category_id]}
        singleSelect
        onSelect={onSelectCategory}
        hidePlaceholder
        displayValue="categoria"
        placeholder="Categoria"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas categorias com esse nome"
      />
    </>
  );
};

export default EditCategory;
