import React from "react";

const ViewMatrix = (props) => {
  const { data } = props;

  return (
    <div>
      <div className="matrix-container">
        <div className="relation flex border">
          <h3 className="ue-h3">Relação:</h3>
        </div>
        <div className="overlapped flex border">
          <h3 className="ue-h3">Sobreposta: {data.sobreposta} </h3>
        </div>
        <div className="cut flex border">
          <h3 className="ue-h3">Corta: {data.corta} </h3>
        </div>
        <div className="cuted flex border">
          <h3 className="ue-h3">Cortada: {data.cortada} </h3>
        </div>
        <div className="fills flex border">
          <h3 className="ue-h3">Preenche: {data.preenche} </h3>
        </div>
        <div className="filled flex border">
          <h3 className="ue-h3">Preenchida: {data.preenchida} </h3>
        </div>
        <div className="pull flex border">
          <h3 className="ue-h3">Encosta: {data.encosta} </h3>
        </div>
        <div className="equal flex border">
          <h3 className="ue-h3">Igual: {data.igual} </h3>
        </div>
      </div>
    </div>
  );
};

export default ViewMatrix;
