import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../api/axios";
import { useForm } from "../../hooks/useForm";
import { CustomToast } from "../../components/customs/Toast/Toast";

const initialValues = {
  username: "",
  password: "",
};

const CreateProfilePage = () => {
  const navigate = useNavigate();
  const { values, handleChange } = useForm(initialValues);
  const [check, setCheck] = useState(false);

  const backToProfiles = () => {
    navigate("/perfis");
  };

  const addProfile = () => {
    axios
      .post("/api/profile", values)
      .then((response) => {
        // toast
        const { msg, error } = response.data;
        CustomToast(error, msg);

        if (!error) {
          navigate("/perfis");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">Criar Novo Perfil</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToProfiles}
        />
      </div>
      <div className="profile-container">
        <div className="username flex border">
          <h3>nome: </h3>
          <input
            className="ue-input"
            style={{ width: "300px", marginLeft: "10px" }}
            type="text"
            name="username"
            onChange={handleChange}
          />
        </div>
        <div
          className="password flex border"
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <h3 className="ue-h3">password: </h3>
          <input
            className="ue-input"
            style={{ width: "300px", marginLeft: "10px", padding: "15px" }}
            type={check ? "text" : "password"}
            name="password"
            onChange={handleChange}
          />
          <FontAwesomeIcon
            style={{ cursor: "pointer", color: "#b7410e", marginLeft: "10px" }}
            onClick={() => setCheck((prev) => !prev)}
            icon={check ? "eye-slash" : "eye"}
            size="lg"
          />
        </div>
      </div>
      <button className="create-ue-btn" onClick={addProfile}>
        Novo Perfil
      </button>
    </div>
  );
};

export default CreateProfilePage;
