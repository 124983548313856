import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../../customs/Input";

import { checkString } from "../../../UE/validations";

import { axios } from "../../../../api/axios";
import useSWR from "swr";

const Objet = (props) => {
  const { setDiverse } = props;
  const { data: objects, mutate } = useSWR("/api/material/diverse/objects");
  const [other, setOther] = useState("");
  const [newObject, setNewObject] = useState({ objeto: "" });

  const addObject = () => {
    if (!checkString(newObject.objeto)) {
      setOther("");
      return;
    }

    axios
      .post("/api/material/diverse/objects", newObject)
      .then(() => {
        setNewObject({ objeto: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (objects) {
      const others = {
        id: 99999,
        objeto: "Outro",
      };

      const index = objects.findIndex((object) => object.id === others.id);

      if (index === -1) {
        objects.push(others);
      }
    }
  }, [objects]);

  if (!objects) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const handleChange = (e) => {
    setNewObject({ objeto: e.target.value });
  };

  const onSelectObject = (selectedList, selectedItem) => {
    setOther(selectedItem.objeto);
    setDiverse((prev) => ({ ...prev, objeto: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Objeto:</h3>
      <div className="others">
        <Multiselect
          options={objects}
          singleSelect
          onSelect={onSelectObject}
          hidePlaceholder
          displayValue="objeto"
          placeholder="Objeto"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados objetos com esse nome"
        />
        <InputField
          other={other}
          value={newObject.objeto}
          handleChange={handleChange}
          add={addObject}
        />
      </div>
    </>
  );
};

export default Objet;
