import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const Cronology = (props) => {
  const {
    setCeramics,
    setLithic,
    setGlass,
    setCoin,
    setDiverse,
    setSample,
    type,
  } = props;
  const { data: cronologies } = useSWR("/api/material/cronologies");

  if (!cronologies) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectCronology = (selectedList, selectedItem) => {
    if (type === "ceramics") {
      setCeramics((prev) => ({ ...prev, cronologia: selectedItem.id }));
    }
    if (type === "lithic") {
      setLithic((prev) => ({ ...prev, cronologia: selectedItem.id }));
    }
    if (type === "glass") {
      setGlass((prev) => ({ ...prev, cronologia: selectedItem.id }));
    }
    if (type === "coin") {
      setCoin((prev) => ({ ...prev, cronologia: selectedItem.id }));
    }
    if (type === "diverse") {
      setDiverse((prev) => ({ ...prev, cronologia: selectedItem.id }));
    }
    if (type === "sample") {
      setSample((prev) => ({ ...prev, cronologia: selectedItem.id }));
    }
  };

  return (
    <>
      <h3 className="ue-h3">Cronologia:</h3>
      <Multiselect
        options={cronologies}
        singleSelect
        onSelect={onSelectCronology}
        hidePlaceholder
        displayValue="cronologia"
        placeholder="Cronologia"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas cronologias com esse nome"
      />
    </>
  );
};

export default Cronology;
