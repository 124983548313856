import React from "react";

const ViewSedimentary = (props) => {
  const { data } = props;

  return (
    <div>
      <div className="sedimentary-container">
        <div className="inclusions border flex">
          <h3 className="ue-h3">Inclusões: {data.inclusões} </h3>
        </div>
        <div className="color border flex">
          <h3 className="ue-h3">Cor: {data.cor} </h3>
        </div>
        <div className="matrix border flex">
          <h3 className="ue-h3">Matriz: {data.matriz} </h3>
        </div>
        <div className="compactness border flex">
          <h3 className="ue-h3">Compacidade: {data.compacidade} </h3>
        </div>
      </div>
    </div>
  );
};

export default ViewSedimentary;
