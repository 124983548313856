import React, { useContext } from "react";
import { Routes as Switch, Route } from "react-router-dom";

import { Context } from "../context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Sidebar from "../components/Sidebar/Sidebar";

import LoginPage from "../pages/Login/LoginPage";
import ProfilePage from "../pages/Profile/ProfilePage";
import ViewProfile from "../pages/Profile/ViewProfile";
import CreateInterventionPage from "../pages/Intervention/CreateInterventionPage";
import Interventions from "../pages/Intervention/Interventions";
import ViewIntervention from "../pages/Intervention/ViewIntervention";
import CreatePollPage from "../pages/Poll/CreatePollPage";
import ViewPoll from "../pages/Poll/ViewPoll";
import CreateUePage from "../pages/UE/CreateUePage";
import EditInterventionPage from "../pages/Intervention/EditInterventionPage";
import EditPollPage from "../pages/Poll/EditPollPage";
import CreateProfilePage from "../pages/Profile/CreateProfilePage";
import ProfilesPage from "../pages/Profile/ProfilesPage";
import ViewUePage from "../pages/UE/ViewUePage";
import EditUePage from "../pages/UE/EditUePage";
import CreateMaterialPage from "../pages/Materials/CreateMaterialPage";
import Materials from "../pages/Materials/Materials";
import ViewMaterialPage from "../pages/Materials/ViewMaterialPage";
import EditMaterialPage from "../pages/Materials/EditMaterialPage";
import Report from "../pages/Report";
import ReportMaterial from "../pages/Report/Material";

const Routes = () => {
  const { authenticated, role } = useContext(Context);

  return (
    <>
      {authenticated && <Sidebar />}
      <Switch>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/perfil"
          element={<ProtectedRoute isPrivate Component={ProfilePage} />}
        />
        {role === "admin" && (
          <Route
            path="/perfil/novo"
            element={<ProtectedRoute isPrivate Component={CreateProfilePage} />}
          />
        )}
        <Route
          path="/perfis"
          element={<ProtectedRoute isPrivate Component={ProfilesPage} />}
        />
        <Route
          path="/perfis/:id"
          element={<ProtectedRoute isPrivate Component={ViewProfile} />}
        />
        <Route
          path="/intervenções"
          element={<ProtectedRoute isPrivate Component={Interventions} />}
        />
        <Route
          path="/intervenções/nova"
          element={
            <ProtectedRoute isPrivate Component={CreateInterventionPage} />
          }
        />
        <Route
          path="/intervenções/:id"
          element={<ProtectedRoute isPrivate Component={ViewIntervention} />}
        />
        <Route
          path="/intervenções/:id/editar"
          element={
            <ProtectedRoute isPrivate Component={EditInterventionPage} />
          }
        />
        <Route
          exact
          path="/sondagens/nova"
          element={<ProtectedRoute isPrivate Component={CreatePollPage} />}
        />
        <Route
          exact
          path="/sondagens/:id"
          element={<ProtectedRoute isPrivate Component={ViewPoll} />}
        />
        <Route
          path="/sondagens/:id/editar"
          element={<ProtectedRoute isPrivate Component={EditPollPage} />}
        />
        <Route
          path="/ue/nova"
          element={<ProtectedRoute isPrivate Component={CreateUePage} />}
        />
        <Route
          path="/ue/:id"
          element={<ProtectedRoute isPrivate Component={ViewUePage} />}
        />
        <Route
          path="/ue/:id/editar"
          element={<ProtectedRoute isPrivate Component={EditUePage} />}
        />
        <Route
          path="/materiais"
          element={<ProtectedRoute isPrivate Component={Materials} />}
        />
        <Route
          path="/materiais/novo"
          element={<ProtectedRoute isPrivate Component={CreateMaterialPage} />}
        />
        <Route
          exact
          path="/material/:id"
          element={<ProtectedRoute isPrivate Component={ViewMaterialPage} />}
        />
        <Route
          path="/material/:id/editar"
          element={<ProtectedRoute isPrivate Component={EditMaterialPage} />}
        />
        <Route
          path="/intervenções/relatório/:id"
          element={<ProtectedRoute isPrivate Component={Report} />}
        />
        <Route
          path="/intervenções/relatório/materiais/:id"
          element={<ProtectedRoute isPrivate Component={ReportMaterial} />}
        />
      </Switch>
    </>
  );
};

export default Routes;
