import React from "react";

import Material from "../../../components/Material/Create/Metal/Material";
import Form from "../../../components/Material/Create/Metal/Form";
import State from "../../../components/Material/Create/Ceramics/State";
import Function from "../../../components/Material/Create/Metal/Function";
import Typology from "../../../components/Material/Create/Metal/Typology";

import "./Metal.css";

const Metal = (props) => {
  const { setMetal } = props;

  const handleChange = (e) => {
    setMetal((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="metal-container">
      <div className="description flex border">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          className="ue-input"
          name="fragmentos"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="material flex border">
        <Material setMetal={setMetal} type="metal" />
      </div>
      <div className="form flex border">
        <Form setMetal={setMetal} />
      </div>
      <div className="state flex border">
        <State setMetal={setMetal} type="metal" />
      </div>
      <div className="function flex border">
        <Function setMetal={setMetal} />
      </div>
      <div className="typology flex border">
        <Typology setMetal={setMetal} />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          className="ue-input"
          name="x"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          className="ue-input"
          name="y"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          className="ue-input"
          name="z"
          type="text"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Metal;
