import React from "react";
import InterfaceData from "./interfaceData";

import "./Interface.css";

const Interface = (props) => {
  const { setInterface } = props;
  return (
    <div>
      <hr />
      <h2>Interface</h2>
      <InterfaceData type="Interface" setInterface={setInterface} />
    </div>
  );
};

export default Interface;
