import React, { createContext, useContext } from "react";
import useAuth from "./hooks/useAuth";

const Context = createContext(null);

const AuthProvider = ({ children }) => {
  const {
    authenticated,
    handleLogin,
    handleLogout,
    id,
    username,
    role,
    loading,
    cookies,
  } = useAuth();

  return (
    <Context.Provider
      value={{
        authenticated,
        handleLogin,
        handleLogout,
        id,
        username,
        role,
        loading,
        cookies,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useAuthContext() {
  return useContext(Context);
}

export { Context, AuthProvider };
