import React from "react";

import EditCronology from "../EditCronology";
import EditType from "./EditType";

const EditSample = (props) => {
  const { setNewMaterial, newMaterial } = props;

  const handleChange = (e) => {
    setNewMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="sample-container">
      <div className="description flex border">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          type="text"
          value={newMaterial.descrição || ""}
          onChange={handleChange}
        />
      </div>
      <div className="sample flex border">
        <EditType {...props} />
      </div>
      <div className="cronology flex border">
        <EditCronology {...props} />
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século:</h3>
        <input
          className="ue-input"
          name="século"
          type="text"
          value={newMaterial.século || ""}
          onChange={handleChange}
        />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          className="ue-input"
          name="x"
          type="text"
          value={newMaterial.x || ""}
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          className="ue-input"
          name="y"
          type="text"
          value={newMaterial.y || ""}
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          className="ue-input"
          name="z"
          type="text"
          value={newMaterial.z || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default EditSample;
