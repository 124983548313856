import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const Interior = (props) => {
  const { setCeramics } = props;
  const { data: exterior } = useSWR("/api/material/ceramics/exterior");

  if (!exterior) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectExterior = (selectedList, selectedItem) => {
    setCeramics((prev) => ({ ...prev, interior: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Acabamento Interior:</h3>
      <Multiselect
        options={exterior}
        singleSelect
        onSelect={onSelectExterior}
        hidePlaceholder
        displayValue="acabamento"
        placeholder="Acabamento"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados acabamentos com esse nome"
      />
    </>
  );
};

export default Interior;
