import React from "react";

import Interpretation from "./interpretation";
import Cronology from "../Cronology";

const InterfaceData = (props) => {
  const { type, setInterface, setConstructive, setSedimentary } = props;

  const handleChange = (e) => {
    if (type === "sedimentary") {
      setSedimentary((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else if (type === "constructive") {
      setConstructive((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else {
      setInterface((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  return (
    <>
      <div className="interface-container">
        <div className="description border flex">
          <h3>Descrição: </h3>
          <textarea
            className="ue-input-data"
            // type="text"
            name="descrição"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="interpretation border flex">
          <Interpretation {...props} />
        </div>
        <div className="cronology border flex">
          <Cronology {...props} />
        </div>
      </div>
    </>
  );
};

export default InterfaceData;
