import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../customs/Input";

import { checkString } from "../validations/index";

import { axios } from "../../../api/axios";
import useSWR from "swr";

const Devices = (props) => {
  const { setConstructive } = props;
  const { data: devices, mutate } = useSWR("/api/ue/constructive/devices");
  const [other, setOther] = useState("");
  const [newDevice, setNewDevice] = useState({ aparelho: "" });

  const addDevice = () => {
    if (!checkString(newDevice.aparelho)) {
      setOther("");
      return;
    }

    axios
      .post("/api/ue/constructive/devices", newDevice)
      .then(() => {
        setNewDevice({ aparelho: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  const onSelectType = (selectedList, selectedItem) => {
    setOther(selectedItem.aparelho);
    setConstructive((prev) => ({
      ...prev,
      aparelho: selectedItem.id_aparelho,
    }));
  };

  const handleChange = (e) => {
    setNewDevice({ aparelho: e.target.value });
  };

  useEffect(() => {
    if (devices) {
      const others = {
        id_aparelho: 9999,
        aparelho: "Outro",
      };

      const index = devices.findIndex(
        (object) => object.id_aparelho === others.id_aparelho
      );

      if (index === -1) {
        devices.push(others);
      }
    }
  }, [devices]);

  if (!devices) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <>
      <h3>Aparelho:</h3>
      <div className="others">
        <Multiselect
          options={devices}
          selectedValues={devices.selectedValue}
          singleSelect
          showArrow
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="aparelho"
          placeholder="aparelho"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados aparelho"
        />
        <InputField
          other={other}
          value={newDevice.aparelho}
          handleChange={handleChange}
          add={addDevice}
        />
      </div>
    </>
  );
};

export default Devices;
