import React from "react";

import Cronology from "../../../components/Material/Create/Ceramics/Cronology";
import Type from "../../../components/Material/Create/Sample/Type";

import "./Sample.css";

const Sample = (props) => {
  const { setSample } = props;

  const handleChange = (e) => {
    setSample((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="sample-container">
      <div className="description flex border">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="sample flex border">
        <Type setSample={setSample} />
      </div>
      <div className="cronology flex border">
        <Cronology setSample={setSample} type="sample" />
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século:</h3>
        <input
          className="ue-input"
          name="século"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          className="ue-input"
          name="x"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          className="ue-input"
          name="y"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          className="ue-input"
          name="z"
          type="text"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Sample;
