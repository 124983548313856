import React from "react";

const EditInterventionData = (props) => {
  const { newIntervention, setNewIntervention } = props;

  const handleChange = (e) => {
    setNewIntervention((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="create-intervention-container">
      <div className="place border flex">
        <h3 className="ue-h3">Sítio:</h3>
        <input
          className="ue-input"
          type="text"
          name="sítio"
          value={newIntervention.sítio || ""}
          onChange={handleChange}
        />
      </div>
      <div className="acronym border flex">
        <h3>Acrónimo:</h3>
        <input
          className="ue-input"
          type="text"
          name="acrónimo"
          value={newIntervention.acrónimo || ""}
          onChange={handleChange}
        />
      </div>
      <div className="latitude border flex">
        <h3 className="ue-h3">Latitude:</h3>
        <input
          className="ue-input"
          type="text"
          name="latitude"
          value={newIntervention.latitude || ""}
          onChange={handleChange}
        />
      </div>
      <div className="longitude border flex">
        <h3 className="ue-h3">Longitude:</h3>
        <input
          className="ue-input"
          type="text"
          name="longitude"
          value={newIntervention.longitude || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default EditInterventionData;
