import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const Material = (props) => {
  const { setMaterial, material, setMaterialName } = props;
  const { data: materials } = useSWR("/api/material/materials");

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectMaterial = (selectedList, selectedItem) => {
    setMaterial((prev) => ({ ...prev, material: selectedItem.id }));
    setMaterialName(selectedItem.material);
  };

  return (
    <>
      <h3 className="ue-h3">Tipo de Material:</h3>
      {material.ue !== 0 ? (
        <Multiselect
          className="ue-input"
          options={materials}
          singleSelect
          onSelect={onSelectMaterial}
          hidePlaceholder
          displayValue="material"
          placeholder="Material"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados materiais com esse nome"
        />
      ) : (
        <h3 className="ue-h3">UE em falta</h3>
      )}
    </>
  );
};

export default Material;
