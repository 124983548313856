import React from "react";

import EditPieces from "./EditPieces";
import EditState from "../EditState";
import EditForm from "./EditForm";
import EditCategory from "./EditCategory";
import EditTypology from "../EditTypology";
import EditCooking from "./EditCooking";
import EditCronology from "../EditCronology";
import EditPainted from "./EditPainted";
import EditExterior from "./EditExterior";
import EditInterior from "./EditInterior";

const EditCeramics = (props) => {
  const { setNewMaterial, newMaterial } = props;

  const handleChange = (e) => {
    setNewMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="ceramics-container">
      <div className="description border flex">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          value={newMaterial.descrição || ""}
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          className="ue-input"
          type="text"
          value={newMaterial.fragmentos || ""}
          name="fragmentos"
          onChange={handleChange}
        />
      </div>
      <div className="piece flex border">
        <EditPieces {...props} />
      </div>
      <div className="form flex border">
        <EditForm {...props} />
      </div>
      <div className="state flex border">
        <EditState {...props} />
      </div>
      <div className="category flex border">
        <EditCategory {...props} />
      </div>
      <div className="typology flex border">
        <EditTypology {...props} />
      </div>
      <div className="cooking flex border">
        <EditCooking {...props} />
      </div>
      <div className="cronology flex border">
        <EditCronology {...props} />
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século:</h3>
        <input
          type="text"
          className="ue-input"
          name="século"
          value={newMaterial.século || ""}
          onChange={handleChange}
        />
      </div>
      <div className="date flex border">
        <h3 className="ue-h3">Data:</h3>
        <input
          type="text"
          className="ue-input"
          name="data"
          value={newMaterial.data || ""}
          onChange={handleChange}
        />
      </div>
      <div className="painted flex border">
        <EditPainted {...props} />
      </div>
      <div className="decoration flex border">
        <h3 className="ue-h3">Decoração:</h3>
        <input
          type="text"
          className="ue-input"
          name="decoração"
          value={newMaterial.decoração || ""}
          onChange={handleChange}
        />
      </div>
      <div className="exterior flex border">
        <EditExterior {...props} />
      </div>
      <div className="interior flex border">
        <EditInterior {...props} />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          type="text"
          className="ue-input"
          name="x"
          value={newMaterial.x || ""}
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          type="text"
          className="ue-input"
          name="y"
          value={newMaterial.y || ""}
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          type="text"
          className="ue-input"
          name="z"
          value={newMaterial.z || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default EditCeramics;
