import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditCooking = (props) => {
  const { data, setNewMaterial } = props;
  const { data: cooking } = useSWR("/api/material/ceramics/cooking");

  if (!cooking) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectCooking = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, cozedura: selectedItem.id }));
  };

  const cookingValue =
    cooking &&
    cooking.map((value) => {
      return [
        {
          id_cozedura: value.id,
          cozedura: value.cozedura,
        },
      ];
    });

  const cooking_id = cookingValue.findIndex(
    (obj) => obj[0].id_cozedura === data.cozedura
  );

  return (
    <>
      <h3 className="ue-h3">Cozedura:</h3>
      <Multiselect
        options={cooking}
        selectedValues={cookingValue[cooking_id]}
        singleSelect
        onSelect={onSelectCooking}
        hidePlaceholder
        displayValue="cozedura"
        placeholder="Cozedura"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas conzeduras com esse nome"
      />
    </>
  );
};

export default EditCooking;
