import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditTypology = (props) => {
  const { data, setNewMaterial } = props;
  const { data: typologies } = useSWR("/api/material/metal/typologies");

  if (!typologies) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectTypology = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, tipologia: selectedItem.id }));
  };

  const typologyValue =
    typologies &&
    typologies.map((value) => {
      return [
        {
          id_tipologia: value.id,
          tipologia: value.tipologia,
        },
      ];
    });

  const typology_id = typologyValue.findIndex(
    (obj) => obj[0].id_tipologia === data.tipologia
  );

  return (
    <>
      <h3 className="ue-h3">Tipologia:</h3>
      <Multiselect
        options={typologies}
        selectedValues={typologyValue[typology_id]}
        singleSelect
        onSelect={onSelectTypology}
        hidePlaceholder
        displayValue="tipologia"
        placeholder="Tipologia"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas tipologias com esse nome"
      />
    </>
  );
};

export default EditTypology;
