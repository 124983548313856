import React, { useState, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { Context } from "../../context/AuthContext";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import MaterialTable from "../../components/Material/MaterialTable";

import useSWR from "swr";

import "./Materials.css";

const Materials = () => {
  const location = useLocation();
  const { id } = useContext(Context);
  const { interventionID: idIntervention } = location?.state;
  const initialValue = idIntervention ? idIntervention : 0;
  const { data: interventions } = useSWR(() => `/api/intervention/${id}`);
  const [interventionID, setInterventionID] = useState(initialValue);

  useEffect(() => {
    setInterventionID(initialValue);
  }, [initialValue]);

  if (!interventions) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectIntervention = (selectedList, selectedItem) => {
    setInterventionID(selectedItem.id);
  };

  const interventionList = interventions.map(({ id, sítio }) => ({
    id,
    sítio,
  }));

  const pieceValue =
    interventions &&
    interventions.map((value) => {
      return [
        {
          id_intervenção: value.id,
          sítio: value.sítio,
        },
      ];
    });

  const piece_id = pieceValue.findIndex(
    (obj) => obj[0].id_intervenção === idIntervention
  );

  return (
    <div className="container">
      <h1>Lista de Materiais</h1>
      <div className="intervention-container">
        <div className="intervention border flex">
          <h3 className="ue-h3">Intervenção:</h3>
          <Multiselect
            className="material-multiselect"
            options={interventionList}
            selectedValues={pieceValue[piece_id]}
            showArrow
            onSelect={onSelectIntervention}
            hidePlaceholder
            singleSelect
            displayValue="sítio"
            placeholder="Intervenção"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas intervenções nesse sítio"
          />
        </div>
      </div>
      <MaterialTable id={interventionID} />
    </div>
  );
};

export default Materials;
