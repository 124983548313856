export const columns = [
  {
    Header: "Nº",
    accessor: "id",
  },
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Último acesso",
    accessor: "access",
  },
];
