import React from "react";

const InterventionList = (props) => {
  const { checkbox, handleChange } = props;

  return (
    <div className="intervention-checkbox">
      <div className="border flex">
        <h3>Lista de Sondagens:</h3>
        <input
          style={{ filter: "hue-rotate(180deg)" }}
          type="checkbox"
          name="polls"
          checked={checkbox.polls}
          onChange={handleChange}
        />
      </div>
      <div className="border flex">
        <h3>Lista de UEs:</h3>
        <input
          style={{ filter: "hue-rotate(180deg)" }}
          type="checkbox"
          name="ues"
          checked={checkbox.ues}
          onChange={handleChange}
        />
      </div>
      <div className="border flex">
        <h3>Lista de Materiais:</h3>
        <input
          style={{ filter: "hue-rotate(180deg)" }}
          type="checkbox"
          name="materials"
          checked={checkbox.materials}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default InterventionList;
