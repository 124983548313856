import React from "react";

import EditMaterial from "../EditMaterial";
import EditState from "../EditState";
import EditTypology from "./EditTypology";
import EditForm from "./EditForm";
import EditFunction from "./EditFunction";

const Metal = (props) => {
  const { setNewMaterial, newMaterial } = props;

  const handleChange = (e) => {
    setNewMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="metal-container">
      <div className="description flex border">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          type="text"
          value={newMaterial.descrição || ""}
          onChange={handleChange}
        />
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          className="ue-input"
          name="fragmentos"
          type="text"
          value={newMaterial.fragmentos || ""}
          onChange={handleChange}
        />
      </div>
      <div className="material flex border">
        <EditMaterial {...props} />
      </div>
      <div className="form flex border">
        <EditForm {...props} />
      </div>
      <div className="state flex border">
        <EditState {...props} />
      </div>
      <div className="function flex border">
        <EditFunction {...props} />
      </div>
      <div className="typology flex border">
        <EditTypology {...props} />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          className="ue-input"
          name="x"
          type="text"
          value={newMaterial.x || ""}
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          className="ue-input"
          name="y"
          type="text"
          value={newMaterial.y || ""}
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          className="ue-input"
          name="z"
          type="text"
          value={newMaterial.z || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Metal;
