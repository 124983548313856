import React from "react";

import MaterialsByType from "./MaterialsByType";
import CronologyByUE from "./CronologyByUE";
import CeramicFormByUE from "./CeramicFormByUE";

const MaterialStatistic = (props) => {
  const { statistic } = props;

  return (
    <>
      {statistic === 1 && <MaterialsByType />}
      {statistic === 2 && <CronologyByUE />}
      {statistic === 3 && <CeramicFormByUE />}
    </>
  );
};

export default MaterialStatistic;
