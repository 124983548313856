import React from "react";

import EditInterfaceData from "./EditInterfaceData";

const EditInterface = (props) => {
  const { data, newUE, setNewUE } = props;

  return (
    <div>
      <EditInterfaceData setNewUE={setNewUE} data={data} newUE={newUE} />
    </div>
  );
};

export default EditInterface;
