import React from "react";

import { View } from "@react-pdf/renderer";

import {
  Table,
  TableCell,
  TableHeader,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

import { table } from "../../pages/Report/Styles";

const ReportTable = (props) => {
  const { report } = props;

  const data = {
    UE: report.num_UE,
    corta: report.corta
      ? report.corta
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
    cortada: report.cortada
      ? report.cortada
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
    encosta: report.encosta
      ? report.encosta
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
    igual: report.igual
      ? report.igual
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
    preenche: report.preenche
      ? report.preenche
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
    preenchida: report.preenchida
      ? report.preenchida
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
    sobreposta: report.sobreposta
      ? report.sobreposta
          .split("|")
          .map((value) => value.trim())
          .join("\n")
      : "",
  };

  return (
    <View wrap={false}>
      <Table data={[data]}>
        <TableHeader textAlign={"center"}>
          <TableCell weighting={0.3} style={table.cell}>
            UE
          </TableCell>
          <TableCell style={table.normalCell}>Sobreposta</TableCell>
          <TableCell style={table.normalCell}>Corta</TableCell>
          <TableCell style={table.normalCell}>Cortada</TableCell>
          <TableCell style={table.normalCell}>Encosta</TableCell>
          <TableCell style={table.normalCell}>Igual</TableCell>
          <TableCell style={table.normalCell}>Preenche</TableCell>
          <TableCell style={table.normalCell}>Preenchida</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={table.cell}
            weighting={0.3}
            getContent={(r) => r.UE}
          />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.sobreposta}
          />
          <DataTableCell style={table.normalCell} getContent={(r) => r.corta} />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.cortada}
          />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.encosta}
          />
          <DataTableCell style={table.normalCell} getContent={(r) => r.igual} />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.preenche}
          />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.preenchida}
          />
        </TableBody>
      </Table>
    </View>
  );
};

export default ReportTable;
