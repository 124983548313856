import React from "react";

const EditUE = (props) => {
  // falta sítio
  const { data, setNewUE, newUE } = props;

  const handleChange = (e) => {
    setNewUE((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="ue-grid-container">
      <div className="place border flex">
        <h3 className="ue-h3">Sítio:</h3>
        <h3 className="ue-h3" style={{ marginLeft: "25px" }}>
          {newUE.sítio || ""}
        </h3>
      </div>
      <div className="acronym border flex">
        <h3 className="ue-h3">UE:</h3>
        <input
          className="ue-input"
          type="text"
          name="num_UE"
          value={newUE.num_UE || ""}
          onChange={handleChange}
        />
      </div>
      <div className="caracterization border flex">
        <h3 className="ue-h3">Caracterização: {data.caracterização} </h3>
      </div>
    </div>
  );
};

export default EditUE;
