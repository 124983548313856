import React from "react";

import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";

import UeType from "../../components/Report/UeType";

import useSWR from "swr";

const Report = () => {
  const { id } = useParams();
  const { data: report } = useSWR(() => `/api/report/intervention/ue/${id}`);

  if (!report) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  Font.registerHyphenationCallback((word) => {
    // Return entire word as unique part
    return [word];
  });

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "black",
      paddingTop: 40,
      paddingRight: 20,
      paddingBottom: 40,
      paddingLeft: 20,
      // padding: 40 30 40 ,
      fontFamily: "Courier",
      fontSize: "10px",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    pageNumbers: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    title: {
      textAlign: "center",
      fontFamily: "Courier-Bold",
      fontSize: "16px",
    },
  });

  return (
    <>
      {report.length > 0 ? (
        <div
          style={{
            margin: "0 0 0 250px",
          }}
        >
          <PDFViewer style={styles.viewer}>
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                  <Text style={styles.title}>Lista de UEs</Text>
                  <Text style={styles.title}>{report[0].sítio}</Text>
                </View>
                <View style={styles.section}>
                  {report.map((report) => (
                    <UeType key={report.id_UE} report={report} />
                  ))}
                </View>
                <Text
                  style={styles.pageNumbers}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </div>
      ) : (
        <div className="container">
          <h2>Intervenção não possui qualquer UE aberta</h2>
        </div>
      )}
    </>
  );
};

export default Report;
