import React from "react";
import PropTypes from "prop-types";

const InputField = (props) => {
  const { other, value, handleChange, add } = props;

  return (
    <>
      {(other === "Outro" || other === "Outros") && (
        <div className="others-input">
          <input
            style={{ width: "190px", marginTop: "3px" }}
            type="text"
            value={value}
            onChange={handleChange}
          />
          <button onClick={add}>Adicionar</button>
        </div>
      )}
    </>
  );
};

export default InputField;

InputField.propTypes = {
  other: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  add: PropTypes.func,
};
