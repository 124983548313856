import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../../customs/Input";

import { checkString } from "../../../UE/validations";

import { axios } from "../../../../api/axios";
import useSWR from "swr";

const Material = (props) => {
  const { setMetal, setCoin, setDiverse, type } = props;
  const { data: materials, mutate } = useSWR("/api/material/metal/materials");
  const [other, setOther] = useState("");
  const [newMaterial, setNewMaterial] = useState({ matéria: "" });

  const addMaterial = () => {
    if (!checkString(newMaterial.matéria)) {
      setOther("");
      return;
    }

    axios
      .post("/api/material/metal/materials", newMaterial)
      .then(() => {
        setNewMaterial({ matéria: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (materials) {
      const others = {
        id: 99999,
        matéria: "Outro",
      };

      const index = materials.findIndex((object) => object.id === others.id);

      if (index === -1) {
        materials.push(others);
      }
    }
  }, [materials]);

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const handleChange = (e) => {
    setNewMaterial({ matéria: e.target.value });
  };

  const onSelectMaterial = (selectedList, selectedItem) => {
    setOther(selectedItem.matéria);
    if (type === "metal") {
      setMetal((prev) => ({ ...prev, matéria: selectedItem.id }));
    }
    if (type === "coin") {
      setCoin((prev) => ({ ...prev, matéria: selectedItem.id }));
    }
    if (type === "diverse") {
      setDiverse((prev) => ({ ...prev, matéria: selectedItem.id }));
    }
  };

  return (
    <>
      <h3 className="ue-h3">Matéria-prima:</h3>
      <div className="others">
        <Multiselect
          options={materials}
          singleSelect
          onSelect={onSelectMaterial}
          hidePlaceholder
          displayValue="matéria"
          placeholder="Matéria"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas matérias com esse nome"
        />
        <InputField
          other={other}
          value={newMaterial.matéria}
          handleChange={handleChange}
          add={addMaterial}
        />
      </div>
    </>
  );
};

export default Material;
