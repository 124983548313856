import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditObject = (props) => {
  const { data, setNewMaterial } = props;
  const { data: objects } = useSWR("/api/material/diverse/objects");

  if (!objects) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectObject = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, objeto: selectedItem.id }));
  };

  const objectValue =
    objects &&
    objects.map((value) => {
      return [
        {
          id_objeto: value.id,
          objeto: value.objeto,
        },
      ];
    });

  const object_id = objectValue.findIndex(
    (obj) => obj[0].id_objeto === data.objeto
  );

  return (
    <>
      <h3 className="ue-h3">Objeto:</h3>
      <Multiselect
        options={objects}
        selectedValues={objectValue[object_id]}
        singleSelect
        onSelect={onSelectObject}
        hidePlaceholder
        displayValue="objeto"
        placeholder="Objeto"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados objetos com esse nome"
      />
    </>
  );
};

export default EditObject;
