import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditType = (props) => {
  const { data, setNewMaterial } = props;
  const { data: types } = useSWR("/api/material/sample/types");

  if (!types) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectType = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, tipo: selectedItem.id }));
  };

  const typeValue =
    types &&
    types.map((value) => {
      return [
        {
          id_tipo: value.id,
          material: value.material,
        },
      ];
    });

  const type_id = typeValue.findIndex((obj) => obj[0].id_tipo === data.tipo);

  return (
    <>
      <h3 className="ue-h3">Tipo:</h3>
      <Multiselect
        options={types}
        selectedValues={typeValue[type_id]}
        singleSelect
        onSelect={onSelectType}
        hidePlaceholder
        displayValue="material"
        placeholder="Tipo"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados tipos com esse nome"
      />
    </>
  );
};

export default EditType;
