import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Toast";

const errorOptions = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: false,
  hideProgressBar: false,
};

const successOptions = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: true,
};

export const CustomToast = (error, msg) => {
  error ? toast.error(msg, errorOptions) : toast.success(msg, successOptions);
};
