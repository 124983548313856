import React from "react";

import Multiselect from "multiselect-react-dropdown";
import { CircularProgress } from "@material-ui/core";

import useSWR from "swr";

const EditMaterials = (props) => {
  const { data, setNewUE } = props;
  const { data: materials } = useSWR("/api/ue/constructive/materials");

  const onSelectMaterial = (selectedList, selectedItem) => {
    setNewUE((prev) => ({
      ...prev,
      material: selectedItem.id_material,
    }));
  };

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const materialsValue =
    materials &&
    materials.map((value) => {
      return [
        {
          id_material: value.id_material,
          material: value.material,
        },
      ];
    });

  const materials_id = materialsValue.findIndex(
    (obj) => obj[0].id_material === data.material
  );

  return (
    <>
      <h3 className="ue-h3">Material:</h3>
      <Multiselect
        options={materials}
        selectedValues={materialsValue[materials_id]}
        singleSelect
        showArrow
        onSelect={onSelectMaterial}
        hidePlaceholder
        displayValue="material"
        placeholder="material"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados materiais"
      />
    </>
  );
};

export default EditMaterials;
