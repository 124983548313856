import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../../customs/Input";

import { checkString } from "../../../UE/validations";

import { axios } from "../../../../api/axios";
import useSWR from "swr";

const Type = (props) => {
  const { setSample } = props;
  const { data: types, mutate } = useSWR("/api/material/sample/types");
  const [other, setOther] = useState("");
  const [newType, setNewType] = useState({ tipo: "" });

  const addType = () => {
    if (!checkString(newType.tipo)) {
      setOther("");
      return;
    }

    axios
      .post("/api/material/sample/types", newType)
      .then(() => {
        setNewType({ tipo: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (types) {
      const others = {
        id: 99999,
        material: "Outro",
      };

      const index = types.findIndex((object) => object.id === others.id);

      if (index === -1) {
        types.push(others);
      }
    }
  }, [types]);

  if (!types) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const handleChange = (e) => {
    setNewType({ tipo: e.target.value });
  };

  const onSelectType = (selectedList, selectedItem) => {
    setOther(selectedItem.material);
    setSample((prev) => ({ ...prev, tipo: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Tipo:</h3>
      <div className="others">
        <Multiselect
          options={types}
          singleSelect
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="material"
          placeholder="Tipo"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados tipos com esse nome"
        />
        <InputField
          other={other}
          value={newType.tipo}
          handleChange={handleChange}
          add={addType}
        />
      </div>
    </>
  );
};

export default Type;
