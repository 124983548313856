import React from "react";

import Cronology from "../../../components/Material/Create/Ceramics/Cronology";
import Typology from "../../../components/Material/Create/Lithic/Typology";
import Material from "../../../components/Material/Create/Lithic/Material";

import "./Lithic.css";

const Lithic = (props) => {
  const { setLithic } = props;

  const handleChange = (e) => {
    setLithic((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="lithic-container">
      <div className="description border flex">
        <h3>Descrição:</h3>
        <textarea
          type="text"
          className="ue-input-data"
          name="descrição"
          onChange={handleChange}
        />
      </div>
      <div className="fragments border flex">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          type="text"
          className="ue-input"
          name="fragmentos"
          onChange={handleChange}
        />
      </div>
      <div className="material border flex">
        <Material setLithic={setLithic} />
      </div>
      <div className="typology border flex">
        <Typology setLithic={setLithic} />
      </div>
      <div className="cronology border flex">
        <Cronology setLithic={setLithic} type="lithic" />
      </div>
      <div className="coordinates border flex">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x border flex">
        <h3 className="ue-h3">X:</h3>
        <input
          type="text"
          className="ue-input"
          name="x"
          onChange={handleChange}
        />
      </div>
      <div className="y border flex">
        <h3 className="ue-h3">Y:</h3>
        <input
          type="text"
          className="ue-input"
          name="y"
          onChange={handleChange}
        />
      </div>
      <div className="z border flex">
        <h3 className="ue-h3">Z:</h3>
        <input
          type="text"
          className="ue-input"
          name="z"
          onChange={handleChange}
        />
      </div>
      <div className="length border flex">
        <h3 className="ue-h3">Comprimento:</h3>
        <input
          type="text"
          className="ue-input"
          name="comprimento"
          onChange={handleChange}
        />
      </div>
      <div className="width border flex">
        <h3 className="ue-h3">Largura:</h3>
        <input
          type="text"
          className="ue-input"
          name="largura"
          onChange={handleChange}
        />
      </div>
      <div className="thickness border flex">
        <h3 className="ue-h3">Espessura:</h3>
        <input
          type="text"
          className="ue-input"
          name="espessura"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Lithic;
