import React from "react";

import Multiselect from "multiselect-react-dropdown";
import { CircularProgress } from "@material-ui/core";

import useSWR from "swr";

const UsersIntervention = (props) => {
  const { setData } = props;
  const { data: users } = useSWR("/api/intervention/users");

  const onSelectUser = (selectedList, selectedItem) => {
    const temp = selectedList.map(({ id_utilizador }) => id_utilizador);
    setData((prev) => ({ ...prev, user: temp }));
  };

  const onRemoveUser = (selectedList, selectedItem) => {
    const temp = selectedList.map(({ id_utilizador }) => id_utilizador);
    setData((prev) => ({ ...prev, user: temp }));
  };

  if (!users) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <div style={{ marginTop: "50px" }}>
      <h3>Utilizadores com acesso a esta Intervenção</h3>
      <Multiselect
        className="editMultiselect"
        options={users}
        showArrow
        onSelect={onSelectUser}
        onRemove={onRemoveUser}
        hidePlaceholder
        displayValue="username"
        placeholder="Utilizador"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados utilizadores com esse nome"
      />
    </div>
  );
};

export default UsersIntervention;
