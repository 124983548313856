import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditExterior = (props) => {
  const { data, setNewMaterial } = props;
  const { data: exterior } = useSWR("/api/material/ceramics/exterior");

  if (!exterior) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectExterior = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, exterior: selectedItem.id }));
  };

  const exteriorValue =
    exterior &&
    exterior.map((value) => {
      return [
        {
          id_exterior: value.id,
          acabamento: value.acabamento,
        },
      ];
    });

  const exterior_id = exteriorValue.findIndex(
    (obj) => obj[0].id_exterior === data.exterior
  );

  return (
    <>
      <h3 className="ue-h3">Acabamento Exterior:</h3>
      <Multiselect
        options={exterior}
        selectedValues={exteriorValue[exterior_id]}
        singleSelect
        onSelect={onSelectExterior}
        hidePlaceholder
        displayValue="acabamento"
        placeholder="Acabamento"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados acabamentos com esse nome"
      />
    </>
  );
};

export default EditExterior;
