import React, { useMemo } from "react";

import { useTable, useSortBy, usePagination } from "react-table";

const StatisticsTable = ({ columns, data }) => {
  const tableData = useMemo(() => [...data], [data]);
  const tableColumms = useMemo(() => [...columns], [columns]);

  const tableInstance = useTable(
    {
      columns: tableColumms,
      data: tableData,
      initialState: {
        pageIndex: 0,
        pageSize: 9999,
      },
    },
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <div style={{ marginBottom: "50px" }}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? "👇" : "☝️") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StatisticsTable;
