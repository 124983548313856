import React from "react";

import Multiselect from "multiselect-react-dropdown";

const EditCut = (props) => {
  const { data, uesPoll, setNewUE } = props;

  const onSelectCut = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setNewUE((prev) => ({ ...prev, corta: teste }));
  };

  const onRemoveCut = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setNewUE((prev) => ({ ...prev, corta: teste }));
  };

  const final = () => {
    const final = uesPoll.filter((object) => {
      if (data.corta.includes(object.id_UE)) {
        return [object];
      }
    });

    return final;
  };

  return (
    <>
      <h3 className="ue-h3">Corta:</h3>
      <Multiselect
        className="matrix-input"
        options={uesPoll}
        selectedValues={final()}
        showArrow
        onSelect={onSelectCut}
        onRemove={onRemoveCut}
        hidePlaceholder
        displayValue="num_UE"
        placeholder="UE"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas ues"
      />
    </>
  );
};

export default EditCut;
