import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    setValues((prevUser) => ({ ...prevUser, [e.target.name]: e.target.value }));
  };

  return {
    values,
    handleChange,
  };
};
