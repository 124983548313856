import React from "react";

const ViewConstructive = (props) => {
  const { data } = props;

  return (
    <div className="constructive-container" style={{ marginTop: "30px" }}>
      <div className="type border flex">
        <h3 className="ue-h3"> Tipo: {data.tipo} </h3>
      </div>
      <div className="device border flex">
        <h3 className="ue-h3"> Aparelho: {data.aparelho} </h3>
      </div>
      <div className="material border flex">
        <h3 className="ue-h3"> Material: {data.material} </h3>
      </div>
      <div className="length border flex">
        <h3 className="ue-h3">Comprimento: {data.comprimento} </h3>
      </div>
      <div className="width border flex">
        <h3 className="ue-h3">Largura: {data.largura} </h3>
      </div>
      <div className="thickness border flex">
        <h3 className="ue-h3">Espessura: {data.espessura} </h3>
      </div>
      <div className="inclusions border flex">
        <h3 className="ue-h3"> Inclusões: {data.inclusões} </h3>
      </div>
    </div>
  );
};

export default ViewConstructive;
