import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TableContext } from "../../context/TableContext";
import Table from "../../components/customs/Table";

const PollTable = (props) => {
  const { polls, newPoll, id, mutatePolls, columns } = props;

  return (
    <>
      <div className="title-container">
        <FontAwesomeIcon
          className="plus-icon"
          icon="plus"
          size="lg"
          onClick={newPoll}
        />
      </div>
      {polls.length && (
        <TableContext.Provider
          value={{
            view: "/sondagens",
            deletePath: "polls",
            id: id,
            mutate: mutatePolls,
            table: "poll",
          }}
        >
          <Table columns={columns} data={polls} />
        </TableContext.Provider>
      )}
    </>
  );
};

export default PollTable;
