import React from "react";

import Multiselect from "multiselect-react-dropdown";
import { CircularProgress } from "@material-ui/core";

import useSWR from "swr";

const EditUsers = (props) => {
  const { data, setNewIntervention } = props;
  const { data: users } = useSWR("/api/intervention/users");

  if (!users) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectUser = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_utilizador }) => id_utilizador);
    setNewIntervention((prev) => ({ ...prev, utilizadores: teste }));
  };

  const onRemoveUser = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_utilizador }) => id_utilizador);
    setNewIntervention((prev) => ({ ...prev, utilizadores: teste }));
  };

  const final = () => {
    return users.filter((object) =>
      data.utilizadores.includes(object.id_utilizador)
    );
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <h3>Utilizadores com acesso a esta Intervenção</h3>
      <Multiselect
        className="editMultiselect"
        options={users}
        selectedValues={final()}
        showArrow
        onSelect={onSelectUser}
        onRemove={onRemoveUser}
        hidePlaceholder
        displayValue="username"
        placeholder="Utilizador"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados utilizadores com esse nome"
      />
    </div>
  );
};

export default EditUsers;
