import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../../customs/Input";

import { checkString } from "../../../UE/validations";

import { axios } from "../../../../api/axios";
import useSWR from "swr";

const Function = (props) => {
  const { setGlass } = props;
  const { data: functions, mutate } = useSWR("/api/material/glass/functions");
  const [other, setOther] = useState("");
  const [newFunction, setNewFunction] = useState({ função: "" });

  const addFunction = () => {
    if (!checkString(newFunction.função)) {
      setOther("");
      return;
    }

    axios
      .post("/api/material/glass/functions", newFunction)
      .then(() => {
        setNewFunction({ peça: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (functions) {
      const others = {
        id: 99999,
        função: "Outro",
      };

      const index = functions.findIndex((object) => object.id === others.id);

      if (index === -1) {
        functions.push(others);
      }
    }
  }, [functions]);

  if (!functions) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const handleChange = (e) => {
    setNewFunction({ função: e.target.value });
  };

  const onSelectFunction = (selectedList, selectedItem) => {
    setOther(selectedItem.função);
    setGlass((prev) => ({ ...prev, função: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Função:</h3>
      <div className="others">
        <Multiselect
          options={functions}
          singleSelect
          onSelect={onSelectFunction}
          hidePlaceholder
          displayValue="função"
          placeholder="Função"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas funções com esse nome"
        />
        <InputField
          other={other}
          value={newFunction.função}
          handleChange={handleChange}
          add={addFunction}
        />
      </div>
    </>
  );
};

export default Function;
