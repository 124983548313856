import React from "react";

import { glassStyles } from "../../../pages/Report/Styles";

import { View, Text } from "@react-pdf/renderer";

const GlassReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={glassStyles.firstContainer} wrap={false}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Nº UE: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Material: {report.material}
          </Text>
        </View>
      </View>
      <Text style={glassStyles.margin}>Descrição: {report.descrição}</Text>
      <Text style={glassStyles.margin}>Nº fragmentos: {report.fragmentos}</Text>
      <View style={glassStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Forma: {report.forma}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Função: {report.peçafunção} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Estado: {report.estado} </Text>
        </View>
      </View>
      <View style={glassStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Século: {report.século} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Data: {report.data} </Text>
        </View>
      </View>
      <View style={glassStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>X: {report.x}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Y: {report.y} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Z: {report.z} </Text>
        </View>
      </View>
    </>
  );
};

export default GlassReport;
