import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import { Navigate } from "react-router-dom";

import { Context } from "../../context/AuthContext";

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const { authenticated, handleLogin } = useContext(Context);

  // if the user goes back to login page after authentication, redirects to /perfil
  if (authenticated) {
    return <Navigate to="/perfil" replace />;
  }

  return (
    <div className="container">
      <h1>Login</h1>
      <Formik initialValues={initialValues} onSubmit={handleLogin}>
        <Form style={{ marginTop: "65px" }}>
          <Field
            style={{ width: "250px", height: "25px", textIndent: "10px" }}
            name="username"
            type="text"
            placeholder="username"
            autoComplete="off"
          />
          <Field
            style={{
              marginLeft: "5px",
              width: "250px",
              height: "25px",
              textIndent: "10px",
            }}
            name="password"
            type="password"
            placeholder="password"
            autoComplete="off"
          />
          <button
            style={{
              width: "80px",
              height: "30px",
              marginLeft: "5px",
              fontSize: "14px",
              borderRadius: "4px",
              fontWeight: "bold",
            }}
            type="submit"
          >
            Login
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
