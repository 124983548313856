import React from "react";
import EditCronology from "../EditCronology";
import EditState from "../EditState";
import EditForm from "./EditForm";
import EditFunction from "./EditFunction";

const EditGlass = (props) => {
  const { newMaterial, setNewMaterial } = props;

  const handleChange = (e) => {
    setNewMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="glass-container">
      <div className="description flex border">
        <h3>Descrição:</h3>
        <textarea
          type="text"
          className="ue-input-data"
          name="descrição"
          value={newMaterial.descrição || ""}
          onChange={handleChange}
        />
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          type="text"
          className="ue-input"
          name="fragmentos"
          value={newMaterial.fragmentos || ""}
          onChange={handleChange}
        />
      </div>
      <div className="form flex border">
        <EditForm {...props} />
      </div>
      <div className="function flex border">
        <EditFunction {...props} />
      </div>
      <div className="state flex border">
        <EditState {...props} />
      </div>
      <div className="cronology flex border">
        <EditCronology {...props} />
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século:</h3>
        <input
          type="text"
          className="ue-input"
          name="século"
          value={newMaterial.século || ""}
          onChange={handleChange}
        />
      </div>
      <div className="date flex border">
        <h3 className="ue-h3">Data:</h3>
        <input
          type="text"
          className="ue-input"
          name="data"
          value={newMaterial.data || ""}
          onChange={handleChange}
        />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          type="text"
          className="ue-input"
          name="x"
          value={newMaterial.x || ""}
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          type="text"
          className="ue-input"
          name="y"
          value={newMaterial.y || ""}
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          type="text"
          className="ue-input"
          name="z"
          value={newMaterial.z || ""}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default EditGlass;
