import React from "react";

import { ceramicStyles } from "../../../pages/Report/Styles";

import { View, Text } from "@react-pdf/renderer";

const CeramicReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={ceramicStyles.firstContainer} wrap={false}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Nº UE: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Material: {report.material}
          </Text>
        </View>
      </View>
      <Text style={ceramicStyles.margin}>Descrição: {report.descrição}</Text>
      <Text style={ceramicStyles.margin}>
        Nº fragmentos: {report.fragmentos}
      </Text>
      <View style={ceramicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Forma: {report.forma}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Peça: {report.peça} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Estado: {report.estado} </Text>
        </View>
      </View>
      <View style={ceramicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Categoria: {report.categoria}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Tipologia: {report.tipologia} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Cozedura: {report.cozedura} </Text>
        </View>
      </View>
      <View style={ceramicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Século: {report.século} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Data: {report.data} </Text>
        </View>
      </View>
      <View style={ceramicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Pintada: {report.pintada}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Decoração: {report.decoração} </Text>
        </View>
      </View>
      <View style={ceramicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Acabamento Exterior: {report.exterior}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Acabamento Interior: {report.interior} </Text>
        </View>
      </View>
      <View style={ceramicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>X: {report.x}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Y: {report.y} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Z: {report.z} </Text>
        </View>
      </View>
    </>
  );
};

export default CeramicReport;
