import React from "react";

const ViewCeramics = (props) => {
  const { data } = props;

  return (
    <div className="ceramics-container">
      <div className="description border flex">
        <h3 className="wordBreak">Descrição: {data.descrição} </h3>
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos: {data.fragmentos} </h3>
      </div>
      <div className="piece flex border">
        <h3 className="ue-h3">Peça: {data.peça} </h3>
      </div>
      <div className="form flex border">
        <h3 className="ue-h3">Forma: {data.forma} </h3>
      </div>
      <div className="state flex border">
        <h3 className="ue-h3">Estado: {data.conservação} </h3>
      </div>
      <div className="category flex border">
        <h3 className="ue-h3">Categoria: {data.categoria} </h3>
      </div>
      <div className="typology flex border">
        <h3 className="ue-h3">Tipologia: {data.tipologia} </h3>
      </div>
      <div className="cooking flex border">
        <h3 className="ue-h3">Cozedura: {data.cozedura} </h3>
      </div>
      <div className="cronology flex border">
        <h3 className="ue-h3">Cronologia: {data.cronologia} </h3>
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século: {data.século} </h3>
      </div>
      <div className="date flex border">
        <h3 className="ue-h3">Data: {data.data} </h3>
      </div>
      <div className="painted flex border">
        <h3 className="ue-h3">Pintada: {data.pintada} </h3>
      </div>
      <div className="decoration flex border">
        <h3 className="ue-h3">Decoração: {data.decoração} </h3>
      </div>
      <div className="exterior flex border">
        <h3 className="ue-h3">Acabamento Exterior: {data.exterior} </h3>
      </div>
      <div className="interior flex border">
        <h3 className="ue-h3">Acabamento Interior: {data.interior} </h3>
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X: {data.x} </h3>
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y: {data.y} </h3>
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z: {data.z} </h3>
      </div>
    </div>
  );
};

export default ViewCeramics;
