import React, { useContext } from "react";

import withRouter from "../../components/HOC/withRouter";

import { TableContext } from "../../context/TableContext";
import { Context } from "../../context/AuthContext";

import { CircularProgress } from "@material-ui/core";

import Table from "../../components/customs/Table/index";
import { columns } from "../../components/customs/Table/Headers/InterventionColumns";

import useSWR from "swr";

const Interventions = () => {
  const { id } = useContext(Context);
  const { data, mutate } = useSWR(() => `api/intervention/${id}`);

  if (!data) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "50vh",
          width: "50px",
          height: "50px",
          color: "#b7410e",
        }}
      />
    );
  }

  return (
    <div className="container" style={{ marginTop: "80px" }}>
      <h1>Lista de Intervenções</h1>
      {!!data?.length ? (
        <TableContext.Provider
          value={{
            table: "intervention",
            view: "/intervenções",
            deletePath: "intervention",
            id: id,
            mutate: mutate,
          }}
        >
          <Table columns={columns} data={data} />
        </TableContext.Provider>
      ) : (
        <div>
          <h2>Não existe nenhuma intervenção de momento</h2>
        </div>
      )}
    </div>
  );
};

export default withRouter(Interventions);
