import React from "react";

import EditTypology from "./EditTypology";
import EditCronology from "../EditCronology";
import EditMaterial from "./EditMaterial";

const EditLithic = (props) => {
  const { setNewMaterial, newMaterial } = props;

  const handleChange = (e) => {
    setNewMaterial((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="lithic-container">
      <div className="description border flex">
        <h3>Descrição:</h3>
        <textarea
          type="text"
          className="ue-input-data"
          name="descrição"
          value={newMaterial.descrição || ""}
          onChange={handleChange}
        />
      </div>
      <div className="fragments border flex">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          type="text"
          className="ue-input"
          name="fragmentos"
          value={newMaterial.fragmentos || ""}
          onChange={handleChange}
        />
      </div>
      <div className="material border flex">
        <EditMaterial {...props} />
      </div>
      <div className="typology border flex">
        <EditTypology {...props} />
      </div>
      <div className="cronology border flex">
        <EditCronology {...props} />
      </div>
      <div className="coordinates border flex">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x border flex">
        <h3 className="ue-h3">X:</h3>
        <input
          type="text"
          className="ue-input"
          name="x"
          value={newMaterial.x || ""}
          onChange={handleChange}
        />
      </div>
      <div className="y border flex">
        <h3 className="ue-h3">Y:</h3>
        <input
          type="text"
          className="ue-input"
          name="y"
          value={newMaterial.y || ""}
          onChange={handleChange}
        />
      </div>
      <div className="z border flex">
        <h3 className="ue-h3">Z:</h3>
        <input
          type="text"
          className="ue-input"
          name="z"
          value={newMaterial.z || ""}
          onChange={handleChange}
        />
      </div>
      <div className="length border flex">
        <h3 className="ue-h3">Comprimento:</h3>
        <input
          type="text"
          className="ue-input"
          name="comprimento"
          value={newMaterial.comprimento || ""}
          onChange={handleChange}
        />
      </div>
      <div className="width border flex">
        <h3 className="ue-h3">Largura:</h3>
        <input
          type="text"
          className="ue-input"
          name="largura"
          value={newMaterial.largura || ""}
          onChange={handleChange}
        />
      </div>
      <div className="thickness border flex">
        <h3 className="ue-h3">Espessura:</h3>
        <input
          type="text"
          className="ue-input"
          name="espessura"
          value={newMaterial.espessura || ""}
          onChange={handleChange}
        />
      </div>
      <div className="measures border flex">
        <h3 className="ue-h3">Medidas:</h3>
      </div>
    </div>
  );
};

export default EditLithic;
