import React from "react";

import Material from "../../../components/Material/Create/Metal/Material";
import Objet from "../../../components/Material/Create/Diverse/Objet";
import Functionality from "../../../components/Material/Create/Diverse/Functionality";
import State from "../../../components/Material/Create/Ceramics/State";
import Cronology from "../../../components/Material/Create/Ceramics/Cronology";

import "./Diverse.css";

const Diverse = (props) => {
  const { setDiverse } = props;

  const handleChange = (e) => {
    setDiverse((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="diverse-container">
      <div className="description flex border">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          className="ue-input"
          name="fragmentos"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="material flex border">
        <Material setDiverse={setDiverse} type="diverse" />
      </div>
      <div className="type flex border">
        <Objet setDiverse={setDiverse} />
      </div>
      <div className="function flex border">
        <Functionality setDiverse={setDiverse} />
      </div>
      <div className="state flex border">
        <State setDiverse={setDiverse} type="diverse" />
      </div>
      <div className="cronology flex border">
        <Cronology setDiverse={setDiverse} type="diverse" />
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século:</h3>
        <input
          className="ue-input"
          name="século"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="data flex border">
        <h3 className="ue-h3">Data:</h3>
        <input
          className="ue-input"
          name="data"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          className="ue-input"
          name="x"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          className="ue-input"
          name="y"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          className="ue-input"
          name="z"
          type="text"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Diverse;
