import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const Cooking = (props) => {
  const { setCeramics } = props;
  const { data: cooking } = useSWR("/api/material/ceramics/cooking");

  if (!cooking) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectCooking = (selectedList, selectedItem) => {
    setCeramics((prev) => ({ ...prev, cozedura: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Cozedura:</h3>
      <Multiselect
        options={cooking}
        singleSelect
        onSelect={onSelectCooking}
        hidePlaceholder
        displayValue="cozedura"
        placeholder="Cozedura"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas cozeduras com esse nome"
      />
    </>
  );
};

export default Cooking;
