import React, { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Multiselect from "multiselect-react-dropdown";
import InputField from "../customs/Input";

import { Context } from "../../context/AuthContext";
import { CustomToast } from "../customs/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { checkString } from "../UE/validations";

import { axios } from "../../api/axios";
import useSWR from "swr";

import UsersIntervention from "./UsersIntervention";

import "./CreateIntervention.css";

const initialState = {
  tipo: -1,
  classificação: -1,
  sítio: "",
  acrónimo: "",
  longitude: "",
  latitude: "",
  user: [],
  created: new Date().toLocaleString(),
};

const CreateIntervention = () => {
  const navigate = useNavigate();
  const { id } = useContext(Context);
  const { data: classifications, mutate } = useSWR(
    "/api/intervention/classification"
  );
  const { data: types } = useSWR("/api/intervention/type");
  const [data, setData] = useState(initialState);
  const [other, setOther] = useState("");
  const [newClassification, setNewClassification] = useState({
    classificação: "",
  });

  const backToInterventions = () => {
    navigate("/intervenções");
  };

  const addClassification = () => {
    if (!checkString(newClassification?.classificação)) {
      setOther("");
      return;
    }

    axios
      .post("/api/intervention/classification", newClassification)
      .then(() => {
        setNewClassification({ classificação: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  const classificationChange = (e) => {
    setNewClassification({ classificação: e.target.value });
  };

  const onSelectType = (selectedList, selectedItem) => {
    setData({
      ...data,
      tipo: selectedItem.id_tipo,
    });
  };

  const onSelectIntervention = (selectedList, selectedItem) => {
    setOther(selectedItem.classificação);
    setData({
      ...data,
      classificação: selectedItem.id_classificação,
    });
  };

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const newIntervention = () => {
    axios
      .post(`/api/intervention/${id}`, data)
      .then((response) => {
        const { msg, error, place, id_intervenção } = response?.data;
        CustomToast(error, msg);
        if (!error) {
          axios
            .post("/api/polls", {
              user: id,
              nome: "Sondagem 1",
              id_sítio: place,
              id_intervenção: id_intervenção,
            })
            .then(() => {
              navigate("/intervenções", {
                state: {
                  msg,
                  error,
                },
              });
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (classifications) {
      const others = {
        id_classificação: 99999,
        classificação: "Outros",
      };

      const index = classifications.findIndex(
        (object) => object.id_classificação === others.id_classificação
      );

      if (index === -1) {
        classifications.push(others);
      }
    }
  }, [classifications]);

  return (
    <div className="container">
      <div className="title-container">
        <h1>Nova Intervenção</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToInterventions}
        />
      </div>
      <h3>Tipo</h3>
      <Multiselect
        className="editMultiselect"
        options={types}
        singleSelect
        showArrow
        onSelect={onSelectType}
        hidePlaceholder
        placeholder="Tipo"
        displayValue="tipo"
        avoidHighlightFirstOption
      />
      <h3>Classificação</h3>
      <div className="ue-input">
        <Multiselect
          className="editMultiselect"
          options={classifications}
          singleSelect
          showArrow
          onSelect={onSelectIntervention}
          hidePlaceholder
          placeholder="Classificação"
          displayValue="classificação"
          avoidHighlightFirstOption
        />
        <InputField
          other={other}
          value={newClassification.classificação}
          handleChange={classificationChange}
          add={addClassification}
        />
      </div>

      <div className="create-intervention-container">
        <div className="place border flex">
          <h3 className="ue-h3">Sítio:</h3>
          <input
            className="ue-input"
            type="text"
            name="sítio"
            onChange={handleChange}
          />
        </div>
        <div className="acronym border flex">
          <h3>Acrónimo:</h3>
          <input
            className="ue-input"
            type="text"
            name="acrónimo"
            onChange={handleChange}
          />
        </div>
        <div className="latitude border flex">
          <h3 className="ue-h3">Latitude:</h3>
          <input
            className="ue-input"
            type="text"
            name="latitude"
            onChange={handleChange}
          />
        </div>
        <div className="longitude border flex">
          <h3 className="ue-h3">Longitude:</h3>
          <input
            className="ue-input"
            type="text"
            name="longitude"
            onChange={handleChange}
          />
        </div>
      </div>
      <UsersIntervention setData={setData} />
      <button className="create-ue-btn" type="submit" onClick={newIntervention}>
        Guardar
      </button>
    </div>
  );
};

export default CreateIntervention;
