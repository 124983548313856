import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditCronology = (props) => {
  const { data, setNewMaterial } = props;
  const { data: cronologies } = useSWR("/api/material/cronologies");

  if (!cronologies) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectCronology = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, cronologia: selectedItem.id }));
  };

  const cronologyValue =
    cronologies &&
    cronologies.map((value) => {
      return [
        {
          id_cronologia: value.id,
          cronologia: value.cronologia,
        },
      ];
    });

  const piece_id = cronologyValue.findIndex(
    (obj) => obj[0].id_cronologia === data.cronologia
  );

  return (
    <>
      <h3 className="ue-h3">Cronologia:</h3>
      <Multiselect
        options={cronologies}
        selectedValues={cronologyValue[piece_id]}
        singleSelect
        onSelect={onSelectCronology}
        hidePlaceholder
        displayValue="cronologia"
        placeholder="Cronologia"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas cronologias com esse nome"
      />
    </>
  );
};

export default EditCronology;
