import React from "react";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import StatisticsTable from "../customs/Table/StatisticsTable";

import useSWR from "swr";

const CeramicFormByUE = () => {
  const { id } = useParams();
  const { data: ceramicFormsByUE } = useSWR(
    () => `/api/report/intervention/${id}/ues/form`
  );

  if (!ceramicFormsByUE) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const header = Object.keys(ceramicFormsByUE[0]).map((key) => ({
    Header: key,
    accessor: key,
  }));

  return <StatisticsTable columns={header} data={ceramicFormsByUE} />;
};

export default CeramicFormByUE;
