import React from "react";

import { View, Text } from "@react-pdf/renderer";

import { sedimentaryStyles } from "../../pages/Report/Styles";

import ReportTable from "./Table";

const SedimentaryReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={sedimentaryStyles.firstContainer}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Identificação: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Tipo: {report.caracterização} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia} </Text>
        </View>
      </View>
      <View style={sedimentaryStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Cor: {report.cor}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Matriz: {report.matriz}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Compacidade: {report.compacidade}</Text>
        </View>
      </View>
      <Text style={sedimentaryStyles.margin}>
        Inclusões: {report.inclusões}
      </Text>
      <Text style={sedimentaryStyles.margin}>
        Descrição: {report.descrição}
      </Text>
      <Text style={sedimentaryStyles.margin}>
        Interpretação: {report.interpretação}
      </Text>
      <ReportTable report={report} />
    </>
  );
};

export default SedimentaryReport;
