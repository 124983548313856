export const materialColumns = [
  {
    Header: "Nº",
    accessor: "id",
  },
  {
    Header: "Tipo Material",
    accessor: "material",
  },
  {
    Header: "UE",
    accessor: "num_UE",
  },
  {
    Header: "User",
    accessor: "id_user",
  },
  {
    Header: "Utilizador",
    accessor: "username",
  },
];
