import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const UEs = (props) => {
  const { interventionID, setMaterial, setMaterialName } = props;
  const { data: ues } = useSWR(() => `/api/intervention/ues/${interventionID}`);

  if (!ues) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectUE = (selectedList, selectedItem) => {
    setMaterial((prev) => ({
      ...prev,
      ue: selectedItem.id,
      sondagem: selectedItem.id_sondagem,
    }));
    setMaterialName("");
  };

  const onRemoveUE = (selectedList, selectedItem) => {
    setMaterial((prev) => ({ ...prev, ue: 0 }));
  };

  const uesList = ues.map(({ id, num_UE, id_sondagem }) => ({
    id,
    num_UE,
    id_sondagem,
  }));

  return (
    <>
      <h3 className="ue-h3">UE:</h3>
      {ues.length > 0 ? (
        <Multiselect
          className="ue-input"
          options={uesList}
          showArrow
          onSelect={onSelectUE}
          onRemove={onRemoveUE}
          hidePlaceholder
          singleSelect
          displayValue="num_UE"
          placeholder="UE"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas UEs com esse número"
        />
      ) : (
        <h3 className="ue-h3">Não existe UEs abertas para esta Intervenção</h3>
      )}
    </>
  );
};

export default UEs;
