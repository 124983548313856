import React from "react";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import StatisticsTable from "../customs/Table/StatisticsTable";

import useSWR from "swr";

const CronologyByUE = () => {
  const { id } = useParams();
  const { data: cronologyByUE } = useSWR(
    () => `/api/report/intervention/${id}/ues/cronology`
  );

  if (!cronologyByUE) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const header = Object.keys(cronologyByUE[0]).map((key) => ({
    Header: key,
    accessor: key,
  }));

  return <StatisticsTable columns={header} data={cronologyByUE} />;
};

export default CronologyByUE;
