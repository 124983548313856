import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

import "./Matrix.css";

const Matrix = (props) => {
  const { id_sondagem, setUe, id_intervenção } = props;
  const { data: ues } = useSWR(() => `/api/ue/${id_sondagem}/ues`);
  const { data: interventionUes } = useSWR(
    () => `/api/intervention/ues/equal/${id_intervenção}`
  );

  const onSelectOverlapped = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, sobreposta: teste }));
  };

  const onRemoveOverlapped = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, sobreposta: teste }));
  };

  const onSelectCut = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, corta: teste }));
  };

  const onRemoveCut = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, corta: teste }));
  };

  const onSelectCutted = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, cortada: teste }));
  };

  const onRemoveCutted = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, cortada: teste }));
  };

  const onSelectFill = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, preenche: teste }));
  };

  const onRemoveFill = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, preenche: teste }));
  };

  const onSelectFilled = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, preenchida: teste }));
  };

  const onRemoveFilled = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, preenchida: teste }));
  };

  const onSelectPull = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, encosta: teste }));
  };

  const onRemovePull = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, encosta: teste }));
  };

  const onSelectEqual = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, igual: teste }));
  };

  const onRemoveEqual = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setUe((prev) => ({ ...prev, igual: teste }));
  };

  if (!ues) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <div>
      <div className="matrix-container">
        <div className="relation flex border">
          <h3 className="ue-h3">Relação:</h3>
        </div>
        <div className="overlapped flex border">
          <h3 className="ue-h3">Sobreposta:</h3>
          <Multiselect
            className="matrix-input"
            options={ues}
            showArrow
            onSelect={onSelectOverlapped}
            onRemove={onRemoveOverlapped}
            hidePlaceholder
            id="sobreposta"
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
        <div className="cut flex border">
          <h3 className="ue-h3">Corta:</h3>
          <Multiselect
            className="matrix-input"
            options={ues}
            showArrow
            onSelect={onSelectCut}
            onRemove={onRemoveCut}
            hidePlaceholder
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
        <div className="cuted flex border">
          <h3 className="ue-h3">Cortada:</h3>
          <Multiselect
            className="matrix-input"
            options={ues}
            showArrow
            onSelect={onSelectCutted}
            onRemove={onRemoveCutted}
            hidePlaceholder
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
        <div className="fills flex border">
          <h3 className="ue-h3">Preenche:</h3>
          <Multiselect
            className="matrix-input"
            options={ues}
            showArrow
            onSelect={onSelectFill}
            onRemove={onRemoveFill}
            hidePlaceholder
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
        <div className="filled flex border">
          <h3 className="ue-h3">Preenchida:</h3>
          <Multiselect
            className="matrix-input"
            options={ues}
            showArrow
            onSelect={onSelectFilled}
            onRemove={onRemoveFilled}
            hidePlaceholder
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
        <div className="pull flex border">
          <h3 className="ue-h3">Encosta:</h3>
          <Multiselect
            className="matrix-input"
            options={ues}
            showArrow
            onSelect={onSelectPull}
            onRemove={onRemovePull}
            hidePlaceholder
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
        <div className="equal flex border">
          <h3 className="ue-h3">Igual:</h3>
          <Multiselect
            className="matrix-input"
            options={interventionUes}
            showArrow
            onSelect={onSelectEqual}
            onRemove={onRemoveEqual}
            hidePlaceholder
            displayValue="num_UE"
            placeholder="UE"
            avoidHighlightFirstOption
            emptyRecordMsg="não foram encontradas ues"
          />
        </div>
      </div>
    </div>
  );
};

export default Matrix;
