import React from "react";

import Category from "../../../components/Material/Create/Ceramics/Category";
import Cooking from "../../../components/Material/Create/Ceramics/Cooking";
import Cronology from "../../../components/Material/Create/Ceramics/Cronology";
import Exterior from "../../../components/Material/Create/Ceramics/Exterior";
import Form from "../../../components/Material/Create/Ceramics/Form";
import Interior from "../../../components/Material/Create/Ceramics/Interior";
import Painted from "../../../components/Material/Create/Ceramics/Painted";
import Pieces from "../../../components/Material/Create/Ceramics/Pieces";
import State from "../../../components/Material/Create/Ceramics/State";
import Typology from "../../../components/Material/Create/Ceramics/Typology";

import "./Ceramics.css";

const Ceramics = (props) => {
  const { setCeramics } = props;

  const handleChange = (e) => {
    setCeramics((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="ceramics-container">
      <div className="description border flex">
        <h3>Descrição:</h3>
        <textarea
          className="ue-input-data"
          name="descrição"
          type="text"
          onChange={handleChange}
        />
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos:</h3>
        <input
          className="ue-input"
          type="text"
          name="fragmentos"
          onChange={handleChange}
        />
      </div>
      <div className="piece flex border">
        <Pieces setCeramics={setCeramics} />
      </div>
      <div className="form flex border">
        <Form setCeramics={setCeramics} />
      </div>
      <div className="state flex border">
        <State setCeramics={setCeramics} type="ceramics" />
      </div>
      <div className="category flex border">
        <Category setCeramics={setCeramics} />
      </div>
      <div className="typology flex border">
        <Typology setCeramics={setCeramics} />
      </div>
      <div className="cooking flex border">
        <Cooking setCeramics={setCeramics} />
      </div>
      <div className="cronology flex border">
        <Cronology setCeramics={setCeramics} type="ceramics" />
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século:</h3>
        <input
          type="text"
          className="ue-input"
          name="século"
          onChange={handleChange}
        />
      </div>
      <div className="date flex border">
        <h3 className="ue-h3">Data:</h3>
        <input
          type="text"
          className="ue-input"
          name="data"
          onChange={handleChange}
        />
      </div>
      <div className="painted flex border">
        <Painted setCeramics={setCeramics} />
      </div>
      <div className="decoration flex border">
        <h3 className="ue-h3">Decoração:</h3>
        <input
          type="text"
          className="ue-input"
          name="decoração"
          onChange={handleChange}
        />
      </div>
      <div className="exterior flex border">
        <Exterior setCeramics={setCeramics} />
      </div>
      <div className="interior flex border">
        <Interior setCeramics={setCeramics} />
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X:</h3>
        <input
          type="text"
          className="ue-input"
          name="x"
          onChange={handleChange}
        />
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y:</h3>
        <input
          type="text"
          className="ue-input"
          name="y"
          onChange={handleChange}
        />
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z:</h3>
        <input
          type="text"
          className="ue-input"
          name="z"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Ceramics;
