import React from "react";

const Interpretation = (props) => {
  const { type, setInterface, setConstructive, setSedimentary } = props;

  const handleChange = (e) => {
    if (type === "sedimentary") {
      setSedimentary((prev) => ({ ...prev, interpretação: e.target.value }));
    } else if (type === "constructive") {
      setConstructive((prev) => ({
        ...prev,
        interpretação: e.target.value,
      }));
    } else {
      setInterface((prev) => ({
        ...prev,
        interpretação: e.target.value,
      }));
    }
  };

  return (
    <>
      <h3>Interpretação:</h3>
      <textarea className="ue-input-data" onChange={handleChange} rows={10} />
    </>
  );
};

export default Interpretation;
