import React from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";

import ViewCeramics from "../../components/Material/View/ViewCeramics";
import ViewLithic from "../../components/Material/View/ViewLithic";
import ViewSample from "../../components/Material/View/ViewSample";
import ViewMetal from "../../components/Material/View/ViewMetal";
import ViewCoin from "../../components/Material/View/ViewCoin";
import ViewDiverse from "../../components/Material/View/ViewDiverse";
import ViewGlass from "../../components/Material/View/ViewGlass";

import useSWR from "swr";

const ViewMaterialPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { interventionID } = location?.state;
  const { data } = useSWR(() => `/api/material/${id}`);

  const backToMaterials = () => {
    navigate(`/intervenções/${interventionID}`, {
      state: {
        interventionID: interventionID,
        page: "materials",
      },
    });
  };

  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">Material</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToMaterials}
        />
      </div>
      <div className="material-container">
        <div className="intervention border flex">
          <h3 className="ue-h3">Sítio: {data.sítio} </h3>
        </div>
        <div className="UE flex border">
          <h3 className="ue-h3">UE: {data.num_UE} </h3>
        </div>
        <div className="material flex border">
          <h3 className="ue-h3">Tipo Material: {data.material} </h3>
        </div>
      </div>
      {data.material === "Cerâmica" && <ViewCeramics data={data} />}
      {data.material === "Lítico" && <ViewLithic data={data} />}
      {data.material === "Amostra" && <ViewSample data={data} />}
      {data.material === "Metal" && <ViewMetal data={data} />}
      {data.material === "Moeda" && <ViewCoin data={data} />}
      {data.material === "Objeto Diverso" && <ViewDiverse data={data} />}
      {data.material === "Vidro" && <ViewGlass data={data} />}
    </div>
  );
};

export default ViewMaterialPage;
