import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditCompactness = (props) => {
  const { data, setNewUE } = props;
  const { data: compactness } = useSWR("/api/ue/sedimentary/compactness");

  const onSelectType = (selectedList, selectedItem) => {
    setNewUE((prev) => ({
      ...prev,
      compacidade: selectedItem.id_compacidade,
    }));
  };

  if (!compactness)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  const compactnessValue =
    compactness &&
    compactness.map((value) => {
      return [
        {
          id_compacidade: value.id_compacidade,
          compacidade: value.compacidade,
        },
      ];
    });

  const compactness_id = compactnessValue.findIndex(
    (obj) => obj[0].id_compacidade === data.compacidade
  );

  return (
    <>
      <h3 className="ue-h3">Compacidade:</h3>
      <Multiselect
        options={compactness}
        selectedValues={compactnessValue[compactness_id]}
        singleSelect
        showArrow
        onSelect={onSelectType}
        hidePlaceholder
        displayValue="compacidade"
        placeholder="compacidade"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas compacidades"
      />
    </>
  );
};

export default EditCompactness;
