import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../customs/Input";

import { checkString } from "../validations";

import { axios } from "../../../api/axios";
import useSWR from "swr";

const Types = (props) => {
  const { setConstructive } = props;
  const { data: types, mutate } = useSWR("/api/ue/constructive/types");
  const [other, setOther] = useState("");
  const [newType, setNewType] = useState({ tipo: "" });

  const addType = () => {
    if (!checkString(newType.tipo)) {
      setOther("");
      return;
    }

    axios.post("/api/ue/constructive/types", newType).then(() => {
      setNewType({ tipo: "" });
      setOther("");
      mutate();
    });
  };

  const onSelectType = (selectedList, selectedItem) => {
    setOther(selectedItem.tipo);
    setConstructive((prev) => ({ ...prev, tipo: selectedItem.id_tipo }));
  };

  const handleChange = (e) => {
    setNewType({ tipo: e.target.value });
  };

  useEffect(() => {
    if (types) {
      const others = {
        id_tipo: 9999,
        tipo: "Outro",
      };

      const index = types.findIndex(
        (object) => object.id_tipo === others.id_tipo
      );

      if (index === -1) {
        types.push(others);
      }
    }
  }, [types]);

  if (!types) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <>
      <h3>Tipo:</h3>
      <div className="others">
        <Multiselect
          options={types}
          singleSelect
          showArrow
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="tipo"
          placeholder="tipo"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados tipos"
        />
        <InputField
          other={other}
          value={newType.tipo}
          handleChange={handleChange}
          add={addType}
        />
      </div>
    </>
  );
};

export default Types;
