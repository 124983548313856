import React, { useState, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { axios } from "../../api/axios";
import { CustomToast } from "../../components/customs/Toast/Toast";
import { Context } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isNullEmptyOrWhitespace } from "../../utils/functions";

import "./CreatePoll.css";

const CreatePollPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sítio, id_sítio, id_intervenção } = location?.state;
  const { id } = useContext(Context);
  const [nome, setNome] = useState("");

  const backToIntervention = () => {
    navigate(`/intervenções/${id_intervenção}`, {
      state: { page: "polls" },
    });
  };

  const createPoll = () => {
    if (isNullEmptyOrWhitespace(nome)) {
      CustomToast(true, "Nome da Sondagem vazio");
      return;
    }

    axios
      .post("/api/polls", {
        user: id,
        nome: nome.trim(),
        id_sítio,
        id_intervenção,
      })
      .then((response) => {
        //   toast
        const { msg, error } = response.data;
        CustomToast(error, msg);

        if (!error) {
          navigate(`/intervenções/${id_intervenção}`);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container">
      <div className="title-container">
        <h1>Nova Sondagem</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToIntervention}
        />
      </div>
      <div className="create-poll-container">
        <div className="poll-button"></div>
        <div className="place border flex">
          <h3 className="h3-title ue-h3">Sítio: </h3>
          <h3 className="ue-h3" style={{ marginLeft: "10px" }}>
            {sítio}
          </h3>
        </div>
        <div className="poll-name border flex">
          <h3 className="ue-h3 h3-title">Nome da Sondagem: </h3>
          <input
            className="ue-input ue-h3"
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </div>
      </div>
      <button className="create-ue-btn" onClick={createPoll}>
        Guardar
      </button>
    </div>
  );
};

export default CreatePollPage;
