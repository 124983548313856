import React from "react";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TableContext } from "../../context/TableContext";
import Table from "../../components/customs/Table";

const MaterialTable = (props) => {
  const { materials, id, materialMutate, materialColumns, sítio } = props;
  const navigate = useNavigate();

  const newMaterial = () => {
    navigate("/materiais/novo", {
      state: {
        intervenção: id,
        sítio: sítio,
      },
    });
  };

  return (
    <>
      <div className="title-container">
        <FontAwesomeIcon
          className="plus-icon"
          icon="plus"
          size="lg"
          onClick={newMaterial}
        />
      </div>
      {materials.length > 0 ? (
        <TableContext.Provider
          value={{
            view: "/material",
            deletePath: "material",
            mutate: materialMutate,
            interventionID: id,
            table: "materials",
          }}
        >
          <Table columns={materialColumns} data={materials} />
        </TableContext.Provider>
      ) : null}
    </>
  );
};

export default MaterialTable;
