import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faEnvelope,
  faLock,
  faSignInAlt,
  faSignOutAlt,
  faUserAlt,
  faSortDown,
  faSortUp,
  faMapMarkedAlt,
  faPlus,
  faMapPin,
  faImages,
  faUsers,
  faBinoculars,
  faEdit,
  faTrashAlt,
  faEyeSlash,
  faEye,
  faQuestionCircle,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faList,
  faArrowLeft,
  faBookOpen,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser,
  faEnvelope,
  faLock,
  faSignInAlt,
  faSignOutAlt,
  faUserAlt,
  faSortDown,
  faSortUp,
  faMapMarkedAlt,
  faPlus,
  faList,
  faMapPin,
  faImages,
  faUsers,
  faBinoculars,
  faEdit,
  faTrashAlt,
  faEyeSlash,
  faEye,
  faQuestionCircle,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faBookOpen,
  faBookmark
);
