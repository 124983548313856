import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditForm = (props) => {
  const { data, setNewMaterial } = props;

  const { data: forms } = useSWR("/api/material/ceramics/forms");

  if (!forms) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectForm = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, forma: selectedItem.id }));
  };

  const formValue =
    forms &&
    forms.map((value) => {
      return [
        {
          id_forma: value.id,
          forma: value.forma,
        },
      ];
    });

  const form_id = formValue.findIndex((obj) => obj[0].id_forma === data.forma);

  return (
    <>
      <h3 className="ue-h3">Forma:</h3>
      <Multiselect
        options={forms}
        selectedValues={formValue[form_id]}
        singleSelect
        onSelect={onSelectForm}
        hidePlaceholder
        displayValue="forma"
        placeholder="Forma"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas formas com esse nome"
      />
    </>
  );
};

export default EditForm;
