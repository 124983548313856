import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import InputField from "../../customs/Input";
import Multiselect from "multiselect-react-dropdown";

import { checkString } from "../validations";

import { axios } from "../../../api/axios";
import useSWR from "swr";

const Compactness = (props) => {
  const { setSedimentary } = props;
  const { data: compactness, mutate } = useSWR(
    "/api/ue/sedimentary/compactness"
  );
  const [other, setOther] = useState("");
  const [newCompactness, setNewCompactness] = useState({ compacidade: "" });

  const addCompactness = () => {
    if (!checkString(newCompactness.compacidade)) {
      setOther("");
      return;
    }

    axios.post("/api/ue/sedimentary/compactness", newCompactness).then(() => {
      setNewCompactness({ compacidade: "" });
      setOther("");
      mutate();
    });
  };

  const onSelectType = (selectedList, selectedItem) => {
    setOther(selectedItem.compacidade);
    setSedimentary((prev) => ({
      ...prev,
      compacidade: selectedItem.id_compacidade,
    }));
  };

  const handleChange = (e) => {
    setNewCompactness({ compacidade: e.target.value });
  };

  useEffect(() => {
    if (compactness) {
      const others = {
        id_compacidade: 9999,
        compacidade: "Outro",
      };

      const index = compactness.findIndex(
        (object) => object.id_compacidade === others.id_compacidade
      );

      if (index === -1) {
        compactness.push(others);
      }
    }
  }, [compactness]);

  if (!compactness) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <>
      <h3 className="ue-h3">Compacidade:</h3>
      <div className="others">
        <Multiselect
          options={compactness}
          selectedValues={compactness.selectedValue}
          singleSelect
          showArrow
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="compacidade"
          placeholder="compacidade"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas compacidades"
        />
        <InputField
          other={other}
          value={newCompactness.compacidade}
          handleChange={handleChange}
          add={addCompactness}
        />
      </div>
    </>
  );
};

export default Compactness;
