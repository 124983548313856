import { useState, useEffect } from "react";

import { axios } from "../../api/axios";
import { CustomToast } from "../../components/customs/Toast/Toast";
import { useCookies } from "react-cookie";

import history from "../../utils/history";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(-1);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [tokens, setTokens] = useState({ accessToken: "", refreshToken: "" });
  const [cookies, setCookies, removeCookie] = useCookies();

  const handleLogin = (values) => {
    const userData = {
      username: values.username,
      password: values.password,
      access: new Date().toLocaleString(),
    };

    axios
      .post("/login", userData)
      .then((response) => {
        if (response) {
          const { data } = response;

          if (data.success) {
            axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
            setAuthenticated(true);
            setId(data.id);
            setUsername(data.username);
            setRole(data.role);
            setTokens({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            });

            setCookies("token", data.accessToken); // your token
            setCookies("name", data.username); // optional data

            history.push("/perfil");
          } else {
            // custom toast com erro
            const { msg, error } = data;
            CustomToast(error, msg);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const handleLogout = () => {
    setAuthenticated(false);
    // axios.defaults.headers.Authorization = undefined;
    // ["token", "name"].forEach((obj) => removeCookie(obj)); // remove data save in cookies
  };

  return {
    authenticated,
    handleLogin,
    handleLogout,
    id,
    username,
    role,
    loading,
    cookies,
  };
}
