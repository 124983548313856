import React, { useState, useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../context/AuthContext";

import "./SubMenu.css";

const SubMenu = ({ item }) => {
  const { role } = useContext(Context);
  const [subnav, setSubnav] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (item.subNav.length > 0) {
      const s = item.subNav.map((item) => {
        return item.role;
      });
      setAdmin(s.every((role) => role === "admin"));
    }
  }, [item.subNav]);

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  return (
    <div>
      <Link
        className="SidebarLink"
        to={item.path}
        onClick={item.subNav && showSubnav}
      >
        <div>
          <FontAwesomeIcon icon={item.icon} size="lg" />
          <span className="SidebarLabel">{item.title}</span>
        </div>
        <div>
          {role === "admin" && admin ? (
            subnav ? (
              <FontAwesomeIcon icon={item.iconOpened} size="lg" />
            ) : (
              <FontAwesomeIcon icon={item.iconClosed} size="lg" />
            )
          ) : role === "user" && !admin ? (
            subnav ? (
              <FontAwesomeIcon icon={item.iconOpened} size="lg" />
            ) : (
              <FontAwesomeIcon icon={item.iconClosed} size="lg" />
            )
          ) : !admin ? (
            subnav ? (
              <FontAwesomeIcon icon={item.iconOpened} size="lg" />
            ) : (
              <FontAwesomeIcon icon={item.iconClosed} size="lg" />
            )
          ) : null}
        </div>
      </Link>
      {subnav &&
        item.subNav.map((item, idx) =>
          item.role === role ? (
            <Link className="DropdownLink" to={item.path} key={idx}>
              <FontAwesomeIcon icon={item.icon} size="lg" />
              <span className="SidebarLabel">{item.title}</span>
            </Link>
          ) : (
            item.role === "user" && (
              <Link className="DropdownLink" to={item.path} key={idx}>
                <FontAwesomeIcon icon={item.icon} size="lg" />
                <span className="SidebarLabel">{item.title}</span>
              </Link>
            )
          )
        )}
    </div>
  );
};

export default SubMenu;
