import React from "react";

import SedimentaryReport from "./SedimentaryReport";
import ConstructiveReport from "./ConstructiveReport";
import InterfaceReport from "./InterfaceReport";

const UeType = (props) => {
  const {
    report: { caracterização },
  } = props;

  return (
    <>
      {caracterização === "Sedimentar" && <SedimentaryReport {...props} />}
      {caracterização === "Construtiva" && <ConstructiveReport {...props} />}
      {(caracterização === "Interface Negativo" ||
        caracterização === "Interface Positivo") && (
        <InterfaceReport {...props} />
      )}
    </>
  );
};

export default UeType;
