import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditUE = (props) => {
  const { data, setNewMaterial } = props;
  const { data: ues } = useSWR(
    () => `/api/intervention/ues/${data.id_intervenção}`
  );

  if (!ues) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectUE = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({
      ...prev,
      UE: selectedItem.id,
    }));
  };

  const uesList = ues.map(({ id, num_UE, id_sondagem }) => ({
    id,
    num_UE,
    id_sondagem,
  }));

  const ueValue =
    ues &&
    ues.map((value) => {
      return [
        {
          id: value.id,
          num_UE: value.num_UE,
        },
      ];
    });

  const value_id = ueValue.findIndex((obj) => obj[0].id === data.UE);

  return (
    <>
      <h3 className="ue-h3">UE:</h3>
      {ues.length > 0 ? (
        <Multiselect
          //   className="ue-input"
          options={uesList}
          selectedValues={ueValue[value_id]}
          showArrow
          onSelect={onSelectUE}
          hidePlaceholder
          singleSelect
          displayValue="num_UE"
          placeholder="UE"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas UEs com esse número"
        />
      ) : (
        <h3 className="ue-h3">Não existe UEs abertas para esta Intervenção</h3>
      )}
    </>
  );
};

export default EditUE;
