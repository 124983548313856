import React, { useContext } from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { TableContext } from "../../context/TableContext";
import Table from "../../components/customs/Table";
import { ueColumns } from "../../components/customs/Table/Headers/UeColumns";

import { Context } from "../../context/AuthContext";

import useSWR from "swr";

import "./ViewPoll.css";

const ViewPoll = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id: intervention } = location?.state;
  const { id: user } = useContext(Context);
  const { data: poll } = useSWR(() => `/api/polls/${user}/0/${id}`);
  const { data: place } = useSWR(() => `/api/place/${poll[0].Sítio_id_sítio}`);
  const { data: ues, mutate } = useSWR(() => `/api/ue/${id}/ue/${user}`);

  const backToIntervention = () => {
    navigate(`/intervenções/${intervention}`, {
      state: { page: "polls" },
    });
  };

  const newUe = () => {
    navigate("/ue/nova", {
      state: {
        id_sondagem: id,
        sítio: place[0].sítio,
        id_intervenção: intervention,
      },
    });
  };

  return (
    <div className="container">
      <div className="title-container">
        <h1>Sondagem</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToIntervention}
        />
      </div>
      {!poll ? (
        <CircularProgress
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15vh",
            width: "50px",
            height: "50px",
          }}
        />
      ) : (
        poll.length && (
          <>
            <div className="show-poll-container">
              <div className="name border flex">
                <h3>Nome:</h3>
                <h3 className="margin-left"> {poll[0].nome_sondagem} </h3>
              </div>
              <div className="place border flex">
                <h3>Sítio:</h3>
                <h3 className="margin-left"> {place && place[0].sítio} </h3>
              </div>
            </div>
            <div className="title-container">
              <h2 style={{ color: "#b7410e" }}>Lista de UEs</h2>
              <FontAwesomeIcon
                className="plus-icon"
                icon="plus"
                size="lg"
                onClick={newUe}
              />
            </div>
            {/* tabela UEs */}
            {ues && ues.length ? (
              <TableContext.Provider
                value={{
                  view: "/ue",
                  deletePath: "ue",
                  id: id,
                  intervention: intervention,
                  mutate: mutate,
                }}
              >
                <Table columns={ueColumns} data={ues} />
              </TableContext.Provider>
            ) : (
              <div>
                <h2>Ainda não existe nenhuma UE aberta para esta sondagem</h2>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default ViewPoll;
