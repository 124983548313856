import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Context } from "../../context/AuthContext";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

import "./Sidebar.css";

const Sidebar = () => {
  const { handleLogout } = useContext(Context);

  return (
    <div className="SidebarNav">
      <div className="SidebarWrap">
        <div className="linkWrapper">
          {SidebarData.map((item) => (
            <SubMenu key={item.id} item={item} />
          ))}
          <div className="logoutDiv">
            <Link to="/" className="SidebarLink" onClick={handleLogout}>
              <div>
                <FontAwesomeIcon icon="sign-out-alt" size="lg" />
                <span className="SidebarLabel">Logout</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
