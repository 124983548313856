import React from "react";

import { coinStyles } from "../../../pages/Report/Styles";

import { View, Text } from "@react-pdf/renderer";

const CoinReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={coinStyles.firstContainer} wrap={false}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Nº UE: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Material: {report.material}
          </Text>
        </View>
      </View>
      <Text style={coinStyles.margin}>Descrição: {report.descrição}</Text>
      <Text style={coinStyles.margin}>Nº fragmentos: {report.fragmentos}</Text>
      <View style={coinStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Matéria-prima: {report.matéria}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Estado: {report.estado} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Valor: {report.valor} </Text>
        </View>
      </View>
      <View style={coinStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Século: {report.século} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Data: {report.data} </Text>
        </View>
      </View>
      <View style={coinStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>X: {report.x}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Y: {report.y} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Z: {report.z} </Text>
        </View>
      </View>
    </>
  );
};

export default CoinReport;
