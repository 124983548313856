import React from "react";

import EditMatrix from "./EditMatrix";
import EditCompactness from "./EditCompactness";
import EditInclusions from "./EditInclusions";

const EditSedimentary = (props) => {
  const { data, newUE, setNewUE } = props;

  const handleChange = (e) => {
    setNewUE((prev) => ({ ...prev, cor: e.target.value }));
  };

  return (
    <div>
      <h2>Sedimentar</h2>
      <div className="sedimentary-container">
        <div className="inclusions border flex">
          <EditInclusions data={data} setNewUE={setNewUE} />
        </div>
        <div className="color border flex">
          <h3 className="ue-h3">Cor:</h3>
          {newUE !== undefined && (
            <input
              className="ue-input"
              type="text"
              name="cor"
              value={newUE.cor || ""}
              onChange={handleChange}
            />
          )}
        </div>
        <div className="matrix border flex">
          <EditMatrix setNewUE={setNewUE} data={data} />
        </div>
        <div className="compactness border flex">
          <EditCompactness data={data} setNewUE={setNewUE} />
        </div>
      </div>
      {/* <EditInterfaceData setNewUE={setNewUE} data={data} newUE={newUE} /> */}
    </div>
  );
};

export default EditSedimentary;
