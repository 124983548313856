import React, { useState, useEffect } from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CustomToast } from "../../components/customs/Toast/Toast";

import EditUE from "../../components/Material/Edit/EditUE";
import EditCeramics from "../../components/Material/Edit/Ceramics";
import EditLithic from "../../components/Material/Edit/Lithic";
import EditSample from "../../components/Material/Edit/Sample";
import EditMetal from "../../components/Material/Edit/Metal";
import EditCoin from "../../components/Material/Edit/Coin";
import EditDiverse from "../../components/Material/Edit/Diverse";
import EditGlass from "../../components/Material/Edit/Glass";

import { axios } from "../../api/axios";
import useSWR from "swr";

// mapping between material types and corresponding components
const materialComponents = {
  Cerâmica: EditCeramics,
  Lítico: EditLithic,
  Amostra: EditSample,
  Metal: EditMetal,
  Moeda: EditCoin,
  "Objeto Diverso": EditDiverse,
  Vidro: EditGlass,
};

const EditMaterialPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { interventionID } = location?.state;
  const { data } = useSWR(() => `/api/material/edit/${id}`);
  const [newMaterial, setNewMaterial] = useState({});

  const SelectedComponent = materialComponents[data?.material];

  const editMaterial = () => {
    axios
      .put(`/api/material/edit/${id}`, {
        ...newMaterial,
        edited: new Date().toLocaleString(),
      })
      .then((response) => {
        const { msg, error } = response?.data;
        CustomToast(error, msg);

        backToMaterials();
      })
      .catch((error) => console.log(error));
  };

  const backToMaterials = () => {
    navigate(`/intervenções/${interventionID}`, {
      state: {
        interventionID: interventionID,
        materials: true,
        page: "materials",
      },
    });
  };

  useEffect(() => {
    if (data) {
      setNewMaterial(data);
    }
  }, [data]);

  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">Material</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToMaterials}
        />
      </div>
      <div className="material-container">
        <div className="intervention border flex">
          <h3 className="ue-h3">Sítio: {data.sítio} </h3>
        </div>
        <div className="UE flex border">
          <EditUE data={data} setNewMaterial={setNewMaterial} />
        </div>
        <div className="material flex border">
          <h3 className="ue-h3">Tipo Material: {data.material} </h3>
        </div>
      </div>
      <SelectedComponent
        data={data}
        setNewMaterial={setNewMaterial}
        newMaterial={newMaterial}
      />
      <button className="create-ue-btn" onClick={editMaterial}>
        Gravar
      </button>
    </div>
  );
};

export default EditMaterialPage;
