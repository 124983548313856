import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../customs/Input";

import { checkString } from "../validations";

import { axios } from "../../../api/axios";
import useSWR from "swr";

const Materials = (props) => {
  const { setConstructive } = props;
  const { data: materials, mutate } = useSWR("/api/ue/constructive/materials");
  const [other, setOther] = useState("");
  const [newMaterial, setNewMaterial] = useState({ material: "" });

  const addMaterial = () => {
    if (!checkString(newMaterial.material)) {
      setOther("");
      return;
    }

    axios.post("/api/ue/constructive/materials", newMaterial).then(() => {
      setNewMaterial("");
      setOther("");
      mutate();
    });
  };

  const onSelectType = (selectedList, selectedItem) => {
    setOther(selectedItem.material);
    setConstructive((prev) => ({
      ...prev,
      material: selectedItem.id_material,
    }));
  };

  const handleChange = (e) => {
    setNewMaterial({ material: e.target.value });
  };

  useEffect(() => {
    if (materials) {
      const others = {
        id_material: 9999,
        material: "Outro",
      };

      const index = materials.findIndex(
        (object) => object.id_material === others.id_material
      );

      if (index === -1) {
        materials.push(others);
      }
    }
  }, [materials]);

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <>
      <h3>Material:</h3>
      <div className="others">
        <Multiselect
          options={materials}
          singleSelect
          showArrow
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="material"
          placeholder="material"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados materiais"
        />
        <InputField
          other={other}
          value={newMaterial.material}
          handleChange={handleChange}
          add={addMaterial}
        />
      </div>
    </>
  );
};

export default Materials;
