import React from "react";

import EditInclusions from "../../Sedimentary/EditInclusions";
import EditDevices from "./EditDevices";
import EditMaterials from "./EditMaterials";
import EditTypes from "./EditTypes";

const EditConstructive = (props) => {
  const { data, setNewUE, newUE } = props;

  const handleChange = (e) => {
    setNewUE((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <h2>Construtiva</h2>
      <div className="constructive-container">
        <div className="type border flex">
          <EditTypes data={data} setNewUE={setNewUE} />
        </div>
        <div className="device border flex">
          <EditDevices data={data} setNewUE={setNewUE} />
        </div>
        <div className="material border flex">
          <EditMaterials data={data} setNewUE={setNewUE} />
        </div>
        <div className="length border flex">
          <h3>Comprimento:</h3>
          <input
            className="ue-input"
            type="text"
            name="comprimento"
            value={newUE.comprimento || ""}
            onChange={handleChange}
          />
        </div>
        <div className="width border flex">
          <h3>Largura:</h3>
          <input
            className="ue-input"
            type="text"
            name="largura"
            value={newUE.largura || ""}
            onChange={handleChange}
          />
        </div>
        <div className="thickness border flex">
          <h3>Espessura:</h3>
          <input
            className="ue-input"
            type="text"
            name="espessura"
            value={newUE.espessura || ""}
            onChange={handleChange}
          />
        </div>
        <div className="inclusions border flex">
          <EditInclusions data={data} setNewUE={setNewUE} />
        </div>
      </div>
    </div>
  );
};

export default EditConstructive;
