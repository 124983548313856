import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditState = (props) => {
  const { data, setNewMaterial } = props;
  const { data: states } = useSWR("/api/material/ceramics/states");

  if (!states) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectState = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, estado: selectedItem.id }));
  };

  const stateValue =
    states &&
    states.map((value) => {
      return [
        {
          id_estado: value.id,
          estado: value.estado,
        },
      ];
    });

  const state_id = stateValue.findIndex(
    (obj) => obj[0].id_estado === data.estado
  );

  return (
    <>
      <h3 className="ue-h3">Estado:</h3>
      <Multiselect
        options={states}
        selectedValues={stateValue[state_id]}
        singleSelect
        onSelect={onSelectState}
        hidePlaceholder
        displayValue="estado"
        placeholder="Estado"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados estados com esse nome"
      />
    </>
  );
};

export default EditState;
