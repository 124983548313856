import React from "react";

import { CircularProgress } from "@material-ui/core";

import { View, Text } from "@react-pdf/renderer";

import { table } from "../../../pages/Report/Styles";

import {
  Table,
  TableCell,
  TableHeader,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

const MaterialsByType = (props) => {
  const { materialsByType } = props;

  if (!materialsByType) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <View wrap={false}>
      <Text
        style={{
          textAlign: "center",
          fontFamily: "Courier-Bold",
          fontSize: "13px",
        }}
      >
        Materiais por Tipo
      </Text>
      <View style={{ marginTop: 20 }}></View>
      <Table data={materialsByType}>
        <TableHeader textAlign={"center"}>
          {/* <TableCell style={table.cell}>Sondagem</TableCell> */}
          <TableCell style={table.cell}>UE</TableCell>
          <TableCell style={table.cell}>Cerâmica</TableCell>
          <TableCell style={table.cell}>Lítico</TableCell>
          <TableCell style={table.cell}>Metal</TableCell>
          <TableCell style={table.cell}>Moeda</TableCell>
          <TableCell style={table.cell}>Objeto Diverso</TableCell>
          <TableCell style={table.cell}>Vidro</TableCell>
          <TableCell style={table.cell}>Total</TableCell>
          <TableCell style={table.cell}>%</TableCell>
        </TableHeader>
        <TableBody>
          {/* <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.nome_sondagem}
          /> */}
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.num_UE}
          />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.cerâmica}
          />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.litico}
          />
          <DataTableCell style={table.normalCell} getContent={(r) => r.metal} />
          <DataTableCell style={table.normalCell} getContent={(r) => r.moeda} />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.diverso}
          />
          <DataTableCell style={table.normalCell} getContent={(r) => r.vidro} />
          <DataTableCell style={table.normalCell} getContent={(r) => r.total} />
          <DataTableCell
            style={table.normalCell}
            getContent={(r) => r.percentagem}
          />
        </TableBody>
      </Table>
    </View>
  );
};

export default MaterialsByType;
