import React from "react";

import EditInterpretation from "./EditInterpretation";
import EditCronology from "./EditCronology";

const EditInterfaceData = (props) => {
  const { data, newUE, setNewUE } = props;

  const handleChange = (e) => {
    setNewUE((prev) => ({ ...prev, descrição: e.target.value }));
  };

  return (
    <>
      <div className="interface-container">
        <div className="description border flex">
          <h3>Descrição:</h3>
          <textarea
            className="ue-input-data"
            type="text"
            name="descrição"
            autoComplete="off"
            value={newUE.descrição || ""}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="interpretation border flex">
          <EditInterpretation {...props} />
        </div>
        <div className="cronology border flex">
          <EditCronology data={data} setNewUE={setNewUE} />
        </div>
      </div>
    </>
  );
};

export default EditInterfaceData;
