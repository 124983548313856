import React from "react";

import { CircularProgress } from "@material-ui/core";

import EditCutted from "./Relations/EditCutted";
import EditOverlapped from "./Relations/EditOverlapped";
import EditCut from "./Relations/EditCut";
import EditFill from "./Relations/EditFill";
import EditFilled from "./Relations/EditFilled";
import EditPull from "./Relations/EditPull";
import EditEqual from "./Relations/EditEqual";

import useSWR from "swr";

const EditMatrix = (props) => {
  const { data, setNewUE, id_sondagem, id_intervenção } = props;
  const { data: uesPoll } = useSWR(
    () => `/api/ue/ues/${id_sondagem}/${data.id_UE}`
  );
  const { data: uesIntervention } = useSWR(
    () => `/api/ue/intervention/${id_intervenção}/${data.id_UE}`
  );

  if (!uesPoll) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  if (!uesIntervention) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <div>
      <div className="matrix-container">
        <div className="relation flex border">
          <h3 className="ue-h3">Relação:</h3>
        </div>
        <div className="overlapped flex border">
          <EditOverlapped data={data} uesPoll={uesPoll} setNewUE={setNewUE} />
        </div>
        <div className="cut flex border">
          <EditCut data={data} uesPoll={uesPoll} setNewUE={setNewUE} />
        </div>
        <div className="cuted flex border">
          <EditCutted data={data} uesPoll={uesPoll} setNewUE={setNewUE} />
        </div>
        <div className="fills flex border">
          <EditFill data={data} uesPoll={uesPoll} setNewUE={setNewUE} />
        </div>
        <div className="filled flex border">
          <EditFilled data={data} uesPoll={uesPoll} setNewUE={setNewUE} />
        </div>
        <div className="pull flex border">
          <EditPull data={data} uesPoll={uesPoll} setNewUE={setNewUE} />
        </div>
        <div className="equal flex border">
          <EditEqual
            data={data}
            uesIntervention={uesIntervention}
            setNewUE={setNewUE}
          />
        </div>
      </div>
    </div>
  );
};

export default EditMatrix;
