import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import { checkString } from "../validations";

import useSWR from "swr";
import { axios } from "../../../api/axios";
import InputField from "../../customs/Input";

const EditInclusions = (props) => {
  const { data, setNewUE } = props;
  const { data: inclusions, mutate } = useSWR("/api/ue/inclusions");
  const [other, setOther] = useState(false);
  const [newInclusion, setNewInclusion] = useState({ inclusão: "" });

  useEffect(() => {
    if (inclusions) {
      const others = {
        id_inclusão: 9999999,
        inclusão: "Outros",
      };

      const index = inclusions.findIndex(
        (object) => object.id_inclusão === others.id_inclusão
      );

      if (index === -1) {
        inclusions.push(others);
      }
    }
  }, [inclusions]);

  if (!inclusions) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const addInclusion = () => {
    if (!checkString(newInclusion.inclusão)) {
      setOther("");
      return;
    }

    axios
      .post("/api/ue/inclusions", newInclusion)
      .then(() => {
        setNewInclusion({ inclusão: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  const onSelectInclusions = (selectedList, selectedItem) => {
    setOther(selectedItem.inclusão);
    const teste = selectedList.map(({ id_inclusão }) => id_inclusão);
    setNewUE((prev) => ({ ...prev, inclusões: teste }));
  };

  const onRemoveInclusions = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_inclusão }) => id_inclusão);
    if (!teste.includes("Outros")) {
      setOther(false);
    }

    setNewUE((prev) => ({ ...prev, inclusões: teste }));
  };

  const final = () => {
    const final = inclusions.filter((object) => {
      if (data.inclusões.includes(object.id_inclusão)) {
        return [object];
      }
    });
    return final;
  };

  const handleChange = (e) => {
    setNewInclusion({ inclusão: e.target.value });
  };

  return (
    <>
      <h3 className="ue-h3">Inclusões:</h3>
      <div className="others">
        <Multiselect
          className="inclusionsMultiselect"
          options={inclusions}
          selectedValues={final()}
          showArrow
          onSelect={onSelectInclusions}
          onRemove={onRemoveInclusions}
          hidePlaceholder
          displayValue="inclusão"
          placeholder="inclusões"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas inclusões com esse nome"
        />
        <InputField
          other={other}
          value={newInclusion.inclusão}
          handleChange={handleChange}
          add={addInclusion}
        />
      </div>
    </>
  );
};

export default EditInclusions;
