import React from "react";

import Multiselect from "multiselect-react-dropdown";
import { CircularProgress } from "@material-ui/core";

import useSWR from "swr";

const EditDevices = (props) => {
  const { data, setNewUE } = props;
  const { data: devices } = useSWR("/api/ue/constructive/devices");

  const onSelectDevice = (selectedList, selectedItem) => {
    setNewUE((prev) => ({
      ...prev,
      aparelho: selectedItem.id_aparelho,
    }));
  };

  if (!devices) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const devicesValue =
    devices &&
    devices.map((value) => {
      return [
        {
          id_aparelho: value.id_aparelho,
          aparelho: value.aparelho,
        },
      ];
    });

  const devices_id = devicesValue.findIndex(
    (obj) => obj[0].id_aparelho === data.aparelho
  );

  return (
    <>
      <h3 className="ue-h3">Aparelho:</h3>
      <Multiselect
        options={devices}
        selectedValues={devicesValue[devices_id]}
        singleSelect
        showArrow
        onSelect={onSelectDevice}
        hidePlaceholder
        displayValue="aparelho"
        placeholder="aparelho"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados aparelhos"
      />
    </>
  );
};

export default EditDevices;
