import React from "react";

const ViewUE = (props) => {
  const { data } = props;

  return (
    <div className="ue-grid-container">
      <div className="place border flex">
        <h3 className="ue-h3">Sítio:</h3>
        <h3 className="ue-h3" style={{ marginLeft: "25px" }}>
          {data.sítio}
        </h3>
      </div>
      <div className="acronym border flex">
        <h3 className="ue-h3">UE: {data.num_UE} </h3>
      </div>
      <div className="caracterization border flex">
        <h3 className="ue-h3">Caracterização: {data.caracterização}</h3>
      </div>
    </div>
  );
};

export default ViewUE;
