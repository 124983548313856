import React from "react";

const ViewSample = (props) => {
  const { data } = props;

  return (
    <div className="sample-container">
      <div className="description flex border">
        <h3 className="wordBreak">Descrição: {data.descrição} </h3>
      </div>
      <div className="sample flex border">
        <h3 className="ue-h3">Material: {data.material_amostra} </h3>
      </div>
      <div className="cronology flex border">
        <h3 className="ue-h3">Cronologia: {data.cronologia} </h3>
      </div>
      <div className="century flex border">
        <h3 className="ue-h3">Século: {data.século} </h3>
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X: {data.x} </h3>
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y: {data.y} </h3>
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z: {data.z} </h3>
      </div>
    </div>
  );
};

export default ViewSample;
