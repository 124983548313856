import React from "react";

import { metalStyles } from "../../../pages/Report/Styles";

import { View, Text } from "@react-pdf/renderer";

const MetalReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={metalStyles.firstContainer} wrap={false}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Nº UE: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Material: {report.material}
          </Text>
        </View>
      </View>
      <Text style={metalStyles.margin}>Descrição: {report.descrição}</Text>
      <Text style={metalStyles.margin}>Nº fragmentos: {report.fragmentos}</Text>
      <View style={metalStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Forma: {report.forma}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Matéria-prima: {report.matéria} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Estado: {report.estado} </Text>
        </View>
      </View>
      <View style={metalStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>X: {report.x}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Y: {report.y} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Z: {report.z} </Text>
        </View>
      </View>
    </>
  );
};

export default MetalReport;
