import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../../customs/Input";

import { checkString } from "../../../UE/validations";

import { axios } from "../../../../api/axios";
import useSWR from "swr";

const Material = (props) => {
  const { setLithic } = props;
  const { data: materials, mutate } = useSWR("/api/material/lithic/materials");
  const [other, setOther] = useState("");
  const [newMaterial, setNewMaterial] = useState({ material: "" });

  const addMaterial = () => {
    if (!checkString(newMaterial.material)) {
      setOther("");
      return;
    }

    axios
      .post("/api/material/lithic/materials", newMaterial)
      .then(() => {
        setNewMaterial({ material: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (materials) {
      const others = {
        id: 99999,
        material: "Outro",
      };

      const index = materials.findIndex((object) => object.id === others.id);

      if (index === -1) {
        materials.push(others);
      }
    }
  }, [materials]);

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const handleChange = (e) => {
    setNewMaterial({ material: e.target.value });
  };

  const onSelectMaterials = (selectedList, selectedItem) => {
    setOther(selectedItem.material);
    setLithic((prev) => ({ ...prev, material_litico: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Material:</h3>
      <div className="others">
        <Multiselect
          options={materials}
          singleSelect
          onSelect={onSelectMaterials}
          hidePlaceholder
          displayValue="material"
          placeholder="Material"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontrados materiais com esse nome"
        />
        <InputField
          other={other}
          value={newMaterial.material}
          handleChange={handleChange}
          add={addMaterial}
        />
      </div>
    </>
  );
};

export default Material;
