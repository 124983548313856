import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditMaterial = (props) => {
  const { data, setNewMaterial } = props;
  const { data: materials } = useSWR("/api/material/metal/materials");

  if (!materials) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectMaterial = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, matéria: selectedItem.id }));
  };

  const materialValue =
    materials &&
    materials.map((value) => {
      return [
        {
          id_matéria: value.id,
          matéria: value.matéria,
        },
      ];
    });

  const material_id = materialValue.findIndex(
    (obj) => obj[0].id_matéria === data.matéria
  );

  return (
    <>
      <h3 className="ue-h3">Matéria-prima:</h3>
      <Multiselect
        options={materials}
        selectedValues={materialValue[material_id]}
        singleSelect
        onSelect={onSelectMaterial}
        hidePlaceholder
        displayValue="matéria"
        placeholder="Matéria"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas matérias com esse nome"
      />
    </>
  );
};

export default EditMaterial;
