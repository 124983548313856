import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../../customs/Input";

import { checkString } from "../../../UE/validations";

import { axios } from "../../../../api/axios";
import useSWR from "swr";

const Typology = (props) => {
  const { setLithic } = props;
  const { data: typologies, mutate } = useSWR(
    "/api/material/lithic/typologies"
  );
  const [other, setOther] = useState("");
  const [newTypology, setNewTypology] = useState({ tipologia: "" });

  const addTypology = () => {
    if (!checkString(newTypology.tipologia)) {
      setOther("");
      return;
    }

    axios
      .post("/api/material/lithic/typologies", newTypology)
      .then(() => {
        setNewTypology({ tipologia: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (typologies) {
      const others = {
        id: 99999,
        tipologia: "Outro",
      };

      const index = typologies.findIndex((object) => object.id === others.id);

      if (index === -1) {
        typologies.push(others);
      }
    }
  }, [typologies]);

  if (!typologies) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const handleChange = (e) => {
    setNewTypology({ tipologia: e.target.value });
  };

  const onSelectTypology = (selectedList, selectedItem) => {
    setOther(selectedItem.tipologia);
    setLithic((prev) => ({ ...prev, tipologia: selectedItem.id }));
  };

  return (
    <>
      <h3 className="ue-h3">Tipologia:</h3>
      <div className="others">
        <Multiselect
          options={typologies}
          singleSelect
          onSelect={onSelectTypology}
          hidePlaceholder
          displayValue="tipologia"
          placeholder="Tipologia"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas tipologias com esse nome"
        />
        <InputField
          other={other}
          value={newTypology.tipologia}
          handleChange={handleChange}
          add={addTypology}
        />
      </div>
    </>
  );
};

export default Typology;
