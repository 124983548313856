import React from "react";

const InterventionData = (props) => {
  const { intervention } = props;

  return (
    <div className="view-intervention-container">
      <div className="classification border flex">
        <h3 className="view-intervention-title">Classificação:</h3>
        <h3 className="intervention-values">{intervention[0].classificação}</h3>
      </div>
      <div className="place border flex">
        <h3 className="ue-h3">Sítio:</h3>
        <h3 className="intervention-values">{intervention[0].sítio}</h3>
      </div>
      <div className="type border flex">
        <h3 className="ue-h3">Tipo:</h3>
        <h3 className="intervention-values">{intervention[0].tipo}</h3>
      </div>
      <div className="acronym border flex">
        <h3>Acrónimo:</h3>
        <h3 className="intervention-values">{intervention[0].acrónimo}</h3>
      </div>
      <div className="latitude border flex">
        <h3>Latitude:</h3>
        <h3 className="intervention-values">{intervention[0].latitude}</h3>
      </div>
      <div className="longitude border flex">
        <h3>Longitude:</h3>
        <h3 className="intervention-values">{intervention[0].longitude}</h3>
      </div>
    </div>
  );
};

export default InterventionData;
