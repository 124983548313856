import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditType = (props) => {
  const { data, setNewIntervention } = props;
  const { data: type } = useSWR("/api/intervention/type");

  const onSelectType = (selectedList, selectedItem) => {
    setNewIntervention((prev) => ({
      ...prev,
      tipo: selectedItem.id_tipo,
    }));
  };

  if (!type) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const typeValue =
    type &&
    type.map((value) => {
      return [
        {
          id_tipo: value.id_tipo,
          tipo: value.tipo,
        },
      ];
    });

  const type_id = typeValue.findIndex((obj) => obj[0].id_tipo === data.tipo);

  return (
    <>
      <Multiselect
        className="editMultiselect"
        options={type}
        selectedValues={typeValue[type_id]}
        singleSelect
        showArrow
        onSelect={onSelectType}
        hidePlaceholder
        displayValue="tipo"
        placeholder="Tipo"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados tipos"
      />
    </>
  );
};

export default EditType;
