import React from "react";

import { Text, View } from "@react-pdf/renderer";

import { constructiveStyles } from "../../pages/Report/Styles";
import ReportTable from "./Table";

const ConstructiveReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={constructiveStyles.firstContainer}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Identificação: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>
            <Text>Tipo: {report.caracterização} </Text>
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia} </Text>
        </View>
      </View>
      <View style={constructiveStyles.container}>
        <View style={{ flex: 1 }}>
          <Text> Tipo: {report.tipo}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text> Aparelho: {report.aparelho}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text> Material: {report.material}</Text>
        </View>
      </View>
      <View style={constructiveStyles.container}>
        <View style={{ flex: 1 }}>
          <Text> Comprimento: {report.comprimento}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text> Largura: {report.largura}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text> Espessura: {report.espessura}</Text>
        </View>
      </View>
      <Text style={constructiveStyles.margin}>
        Inclusões: {report.inclusões}
      </Text>
      <Text style={constructiveStyles.margin}>
        Descrição: {report.descrição}
      </Text>
      <Text style={constructiveStyles.margin}>
        Interpretação: {report.interpretação}
      </Text>
      <ReportTable report={report} />
    </>
  );
};

export default ConstructiveReport;
