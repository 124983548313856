import React, { useState, useEffect } from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CustomToast } from "../../components/customs/Toast/Toast";

import EditUE from "../../components/UE/EditUE";
import EditConstructive from "../../components/UE/Constructive/Edit/EditConstructive";
import EditSedimentary from "../../components/UE/Sedimentary/EditSedimentary";
import EditInterface from "../../components/UE/Interface/Edit/EditInterface";
import EditMatrix from "../../components/UE/Matrix/EditMatrix";

import useSWR from "swr";
import { axios } from "../../api/axios";

const EditUePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    id: poll,
    intervention,
    table,
    id_sondagem,
    id_intervenção,
  } = location?.state;
  const { data } = useSWR(() => `/api/ue/edit/0/${id}`);
  const [newUE, setNewUE] = useState({});

  const backToPoll = () => {
    table === "interventionUes"
      ? navigate(`/intervenções/${poll}`, {
          state: {
            id: intervention,
            page: "ues",
          },
        })
      : navigate(`/sondagens/${poll}`, { state: { id: intervention } });
  };

  const editUE = () => {
    newUE.data = new Date().toLocaleString();

    axios
      .put("/api/ue/edit", newUE)
      .then((response) => {
        const { msg, error } = response.data;
        CustomToast(error, msg);

        if (!error) {
          table === "interventionUes"
            ? navigate(`/intervenções/${poll}`, {
                state: {
                  id: intervention,
                  page: "ues",
                },
              })
            : navigate(`/sondagens/${poll}`, { state: { id: intervention } });
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) {
      setNewUE(data);
    }
  }, [data]);

  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">FICHA DE UE</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToPoll}
        />
      </div>
      <EditUE data={data} setNewUE={setNewUE} newUE={newUE} />
      {data.caracterização === "Sedimentar" && (
        <EditSedimentary setNewUE={setNewUE} data={data} newUE={newUE} />
      )}
      {data.caracterização === "Construtiva" && (
        <EditConstructive setNewUE={setNewUE} data={data} newUE={newUE} />
      )}
      <EditInterface data={data} newUE={newUE} setNewUE={setNewUE} />
      <EditMatrix
        data={data}
        setNewUE={setNewUE}
        id_sondagem={id_sondagem}
        id_intervenção={id_intervenção}
      />
      <button className="create-ue-btn" onClick={editUE}>
        Gravar
      </button>
    </div>
  );
};

export default EditUePage;
