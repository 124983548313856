import React from "react";

import Multiselect from "multiselect-react-dropdown";

const option = [
  {
    id: 1,
    estado: "Sim",
  },
  {
    id: 2,
    estado: "Não",
  },
];

const EditPainted = (props) => {
  const { data, setNewMaterial } = props;

  const onSelectPainted = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, pintada: selectedItem.estado }));
  };

  const paintedValue = option.map((value) => {
    return [
      {
        id_pintada: value.id,
        estado: value.estado,
      },
    ];
  });

  const painted_id = paintedValue.findIndex(
    (obj) => obj[0].estado === data.pintada
  );

  return (
    <>
      <h3 className="ue-h3">Pintada:</h3>
      <Multiselect
        options={option}
        selectedValues={paintedValue[painted_id]}
        singleSelect
        onSelect={onSelectPainted}
        hidePlaceholder
        displayValue="estado"
        placeholder="Pintada"
        avoidHighlightFirstOption
      />
    </>
  );
};

export default EditPainted;
