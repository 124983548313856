import React from "react";
import PropTypes from "prop-types";

const Pagination = (props) => {
  const {
    pageIndex,
    pageOptions,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    pageCount,
    pageSize,
    setPageSize,
  } = props;

  return (
    <div style={{ marginTop: "3px" }}>
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {"<<"}
      </button>
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {"<"}
      </button>
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        {">"}
      </button>
      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {">>"}
      </button>
      <span>
        Página
        <strong>
          {pageIndex + 1} de {pageOptions.length}
        </strong>
      </span>
      <span>
        | Ir para página:
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px" }}
        />
      </span>
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 20, 30, 40, 50, 75, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Mostrar {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

Pagination.propTypes = {
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  pageCount: PropTypes.number,
};

export default Pagination;
