import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const State = (props) => {
  const { setCeramics, setGlass, setMetal, setCoin, setDiverse, type } = props;
  const { data: states } = useSWR("/api/material/ceramics/states");

  if (!states) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectState = (selectedList, selectedItem) => {
    if (type === "ceramics") {
      setCeramics((prev) => ({ ...prev, estado: selectedItem.id }));
    }
    if (type === "glass") {
      setGlass((prev) => ({ ...prev, estado: selectedItem.id }));
    }
    if (type === "metal") {
      setMetal((prev) => ({ ...prev, estado: selectedItem.id }));
    }
    if (type === "coin") {
      setCoin((prev) => ({ ...prev, estado: selectedItem.id }));
    }
    if (type === "diverse") {
      setDiverse((prev) => ({ ...prev, estado: selectedItem.id }));
    }
  };

  return (
    <>
      <h3 className="ue-h3">Estado:</h3>
      <Multiselect
        options={states}
        singleSelect
        onSelect={onSelectState}
        hidePlaceholder
        displayValue="estado"
        placeholder="Estado"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados estados com esse nome"
      />
    </>
  );
};

export default State;
