import React from "react";

import { useParams } from "react-router-dom";

import useSWR from "swr";

const ViewProfile = () => {
  const { id } = useParams();
  const { data } = useSWR(() => `/api/profile/${id}`);

  return (
    <div className="container">
      <h1>Perfil de {data?.username} </h1>
    </div>
  );
};

export default ViewProfile;
