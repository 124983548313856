import React from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ViewUE from "../../components/UE/ViewUE";
import ViewSedimentary from "../../components/UE/Sedimentary/ViewSedimentary";
import ViewConstructive from "../../components/UE/Constructive/ViewConstructive";
import ViewInterface from "../../components/UE/Interface/ViewInterface";
import ViewMatrix from "../../components/UE/Matrix/ViewMatrix";

import useSWR from "swr";

const ViewUePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { id: poll, intervention, table } = location?.state;
  const { data } = useSWR(() => `/api/ue/0/${id}`);

  const backToPoll = () => {
    table === "interventionUes"
      ? navigate(`/intervenções/${poll}`, {
          state: { id: intervention, page: "ues" },
        })
      : navigate(`/sondagens/${poll}`, { state: { id: intervention } });
  };

  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  return (
    <div className="container">
      <div className="title-container">
        <h1 className="ue-title">FICHA DE UE</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToPoll}
        />
      </div>
      <ViewUE data={data} />
      {data.caracterização === "Sedimentar" && <ViewSedimentary data={data} />}
      {data.caracterização === "Construtiva" && (
        <ViewConstructive data={data} />
      )}
      <ViewInterface data={data} />
      <ViewMatrix data={data} />
      {/* <div className="border flex" style={{ marginTop: "15px" }}>
        <h3 className="ue-h3">Data: {data.data.split(",")[0]} </h3>
      </div> */}
    </div>
  );
};

export default ViewUePage;
