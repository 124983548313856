import React from "react";

const EditInterpretation = (props) => {
  const { setNewUE, newUE } = props;

  const handleChange = (e) => {
    setNewUE((prev) => ({ ...prev, interpretação: e.target.value }));
  };

  return (
    <>
      <h3>Interpretação:</h3>
      <textarea
        className="ue-input-data"
        type="text"
        name="interpretação"
        autoComplete="off"
        value={newUE.interpretação || ""}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

export default EditInterpretation;
