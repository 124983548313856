export const ueColumns = [
  {
    Header: "Nº",
    accessor: "id",
  },
  {
    Header: "User",
    accessor: "id_user",
  },
  {
    Header: "Sondagem",
    accessor: "id_sondagem",
  },
  {
    Header: "Intervenção",
    accessor: "id_intervenção",
  },
  {
    Header: "Nº UE",
    accessor: "num_UE",
  },
  {
    Header: "Caracterização",
    accessor: "caracterização",
  },
  {
    Header: "Utilizador",
    accessor: "username",
  },
];
