import React from "react";

import Types from "./types";
import Devices from "./devices";
import Materials from "./materials";
import Inclusions from "../Sedimentary/inclusions";

import "./Constructive.css";
import InterfaceData from "../Interface/interfaceData";

const Construtiva = (props) => {
  const { setConstructive, id } = props;

  const handleChange = (e) => {
    setConstructive((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <hr />
      <h2>Construtiva</h2>
      <div className="constructive-container">
        <div className="type border flex">
          <Types setConstructive={setConstructive} />
        </div>
        <div className="device border flex">
          <Devices setConstructive={setConstructive} />
        </div>
        <div className="material border flex">
          <Materials setConstructive={setConstructive} />
        </div>
        <div className="length border flex">
          <h3>Comprimento:</h3>
          <input
            className="ue-input"
            type="text"
            name="comprimento"
            onChange={handleChange}
          />
        </div>
        <div className="width border flex">
          <h3>Largura:</h3>
          <input
            className="ue-input"
            type="text"
            name="largura"
            onChange={handleChange}
          />
        </div>
        <div className="thickness border flex">
          <h3>Espessura:</h3>
          <input
            className="ue-input"
            type="text"
            name="espessura"
            onChange={handleChange}
          />
        </div>
        <div className="inclusions border flex">
          <Inclusions setConstructive={setConstructive} id={id} />
        </div>
      </div>
      <InterfaceData type="constructive" setConstructive={setConstructive} />
    </div>
  );
};

export default Construtiva;
