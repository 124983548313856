import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditClassification = (props) => {
  const { data, setNewIntervention } = props;
  const { data: classification } = useSWR("/api/intervention/classification");

  const onSelectClassification = (selectedList, selectedItem) => {
    setNewIntervention((prev) => ({
      ...prev,
      classificação: selectedItem.id_classificação,
    }));
  };

  if (!classification) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const classificationValue =
    classification &&
    classification.map((value) => {
      return [
        {
          id_classificação: value.id_classificação,
          classificação: value.classificação,
        },
      ];
    });

  const classification_id = classificationValue.findIndex(
    (obj) => obj[0].id_classificação === data.classificação
  );

  return (
    <>
      <Multiselect
        className="editMultiselect"
        options={classification}
        selectedValues={classificationValue[classification_id]}
        singleSelect
        showArrow
        onSelect={onSelectClassification}
        hidePlaceholder
        displayValue="classificação"
        placeholder="Classificação"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas classificações"
      />
    </>
  );
};

export default EditClassification;
