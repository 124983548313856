import React from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";

import useSWR from "swr";

const EditFunction = (props) => {
  const { data, setNewMaterial } = props;
  const { data: functions } = useSWR("/api/material/diverse/functions");

  if (!functions) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const onSelectFunctionality = (selectedList, selectedItem) => {
    setNewMaterial((prev) => ({ ...prev, função: selectedItem.id }));
  };

  const functionValue =
    functions &&
    functions.map((value) => {
      return [
        {
          id_funcionalidade: value.id,
          funcionalidade: value.funcionalidade,
        },
      ];
    });

  const function_id = functionValue.findIndex(
    (obj) => obj[0].id_funcionalidade === data.função
  );

  return (
    <>
      <h3 className="ue-h3">Funcionalidade:</h3>
      <Multiselect
        options={functions}
        selectedValues={functionValue[function_id]}
        singleSelect
        onSelect={onSelectFunctionality}
        hidePlaceholder
        displayValue="funcionalidade"
        placeholder="Funcionalidade"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas funcionalidades com esse nome"
      />
    </>
  );
};

export default EditFunction;
