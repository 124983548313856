// tudo id
export const materialValues = {
  intervenção: 0,
  ue: 0,
  sondagem: 0,
  utilizador: 0,
  material: 0,
  created: new Date().toLocaleString(),
};

export const ceramicsValues = {
  descrição: "",
  fragmentos: "",
  peça: null,
  forma: null,
  estado: null,
  cronologia: null,
  categoria: null,
  tipologia: null,
  cozedura: null,
  século: "",
  data: "",
  pintada: "",
  decoração: "",
  exterior: null,
  interior: null,
  x: "",
  y: "",
  z: "",
};

export const lithicValues = {
  descrição: "",
  fragmentos: "",
  material_litico: null,
  tipologia: null,
  comprimento: "",
  largura: "",
  espessura: "",
  cronologia: null,
  x: "",
  y: "",
  z: "",
};

export const glassValues = {
  descrição: "",
  fragmentos: "",
  forma: null,
  função: null,
  estado: null,
  cronologia: null,
  século: "",
  data: "",
  x: "",
  y: "",
  z: "",
};

export const metalValues = {
  descrição: "",
  fragmentos: "",
  matéria: null,
  tipologia: null,
  forma: null,
  função: null,
  estado: null,
  x: "",
  y: "",
  z: "",
};

export const coinValues = {
  descrição: "",
  fragmentos: "",
  matéria: null,
  estado: null,
  valor: "",
  cronologia: null,
  século: "",
  data: "",
  x: "",
  y: "",
  z: "",
};

export const diverseValues = {
  descrição: "",
  fragmentos: "",
  matéria: null,
  objeto: null,
  função: null,
  estado: null,
  cronologia: null,
  século: "",
  data: "",
  x: "",
  y: "",
  z: "",
};

export const sampleValues = {
  descrição: "",
  tipo: null,
  cronologia: null,
  século: "",
  x: "",
  y: "",
  z: "",
};
