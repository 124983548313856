import React from "react";

const ViewLithic = (props) => {
  const { data } = props;

  return (
    <div className="lithic-container">
      <div className="description border flex">
        <h3 className="wordBreak">Descrição: {data.descrição} </h3>
      </div>
      <div className="fragments border flex">
        <h3 className="ue-h3">Nº fragmentos: {data.fragmentos} </h3>
      </div>
      <div className="material border flex">
        <h3 className="ue-h3">Material: {data.material_litico} </h3>
      </div>
      <div className="typology border flex">
        <h3 className="ue-h3">Tipologia: {data.tipologia} </h3>
      </div>
      <div className="cronology border flex">
        <h3 className="ue-h3">Cronologia: {data.cronologia} </h3>
      </div>
      <div className="coordinates border flex">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x border flex">
        <h3 className="ue-h3">X: {data.x} </h3>
      </div>
      <div className="y border flex">
        <h3 className="ue-h3">Y: {data.y} </h3>
      </div>
      <div className="z border flex">
        <h3 className="ue-h3">Z: {data.z} </h3>
      </div>
      <div className="length border flex">
        <h3 className="ue-h3">Comprimento: {data.comprimento} </h3>
      </div>
      <div className="width border flex">
        <h3 className="ue-h3">Largura: {data.largura} </h3>
      </div>
      <div className="thickness border flex">
        <h3 className="ue-h3">Espessura: {data.espessura} </h3>
      </div>
    </div>
  );
};

export default ViewLithic;
