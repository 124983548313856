import React from "react";

import CeramicReport from "./Material/CeramicReport";
import GlassReport from "./Material/GlassReport";
import LithicReport from "./Material/LithicReport";
import CoinReport from "./Material/CoinReport";
import SampleReport from "./Material/SampleReport";
import MetalReport from "./Material/MetalReport";
import DiverseReport from "./Material/DiverseReport";

const UeMaterial = (props) => {
  const {
    report: { material },
  } = props;

  return (
    <>
      {material === "Cerâmica" && <CeramicReport {...props} />}
      {material === "Lítico" && <LithicReport {...props} />}
      {material === "Vidro" && <GlassReport {...props} />}
      {material === "Moeda" && <CoinReport {...props} />}
      {material === "Amostra" && <SampleReport {...props} />}
      {material === "Metal" && <MetalReport {...props} />}
      {material === "Objeto Diverso" && <DiverseReport {...props} />}
    </>
  );
};

export default UeMaterial;
