import React from "react";

import { TableContext } from "../../context/TableContext";
import Table from "../../components/customs/Table";

const UeTable = (props) => {
  const { ues, id, ueMutate, ueColumns } = props;

  return (
    <>
      {ues.length > 0 ? (
        <TableContext.Provider
          value={{
            view: "/ue",
            deletePath: "ue",
            id: id,
            mutate: ueMutate,
            table: "interventionUes",
          }}
        >
          <Table columns={ueColumns} data={ues} />
        </TableContext.Provider>
      ) : null}
    </>
  );
};

export default UeTable;
