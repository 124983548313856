export const columns = [
  {
    Header: "Nº",
    accessor: "id",
  },
  {
    Header: "Acrónimo",
    accessor: "acrónimo",
  },
  {
    Header: "Sítio",
    accessor: "sítio",
  },
];
