import React from "react";

import { Text, View } from "@react-pdf/renderer";

import { interfaceStyles } from "../../pages/Report/Styles";
import ReportTable from "./Table";

const InterfaceReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={interfaceStyles.firstContainer}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Identificação: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Tipo: {report.caracterização} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia} </Text>
        </View>
      </View>
      <View style={interfaceStyles.margin}>
        <Text>Descrição: {report.descrição} </Text>
      </View>
      <View style={interfaceStyles.margin}>
        <Text>Interpretação: {report.interpretação} </Text>
      </View>
      <ReportTable report={report} />
    </>
  );
};

export default InterfaceReport;
