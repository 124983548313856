import React from "react";

import { CircularProgress } from "@material-ui/core";

import { TableContext } from "../../context/TableContext";

import Table from "../../components/customs/Table/index";
import { columns } from "../../components/customs/Table/Headers/ProfileColumns";
import useSWR from "swr";

const ProfilesPage = () => {
  const { data, mutate } = useSWR("/api/profile");

  if (!data) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <div className="container">
      <h1>Lista de Perfis</h1>
      <TableContext.Provider
        value={{
          view: "/perfis",
          deletePath: "profile",
          mutate: mutate,
          table: "profilesTable",
        }}
      >
        <Table columns={columns} data={data} />
      </TableContext.Provider>
    </div>
  );
};

export default ProfilesPage;
