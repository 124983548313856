import React, { useState, useEffect, useContext } from "react";

import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { CustomToast } from "../../components/customs/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useSWR from "swr";
import { axios } from "../../api/axios";

import { Context } from "../../context/AuthContext";

import "./ViewPoll.css";

const EditPollPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { id: intervention } = location?.state;
  const { id: userID } = useContext(Context);
  const { data, error } = useSWR(`/api/polls/${userID}/0/${id}`);
  const { data: place } = useSWR(() => `/api/place/${data[0].Sítio_id_sítio}`);
  const [newPoll, setNewPoll] = useState({ nome: "" });

  const backToIntervention = () => {
    navigate(`/intervenções/${intervention}`, {
      state: {
        id: intervention,
        pages: "ues",
      },
    });
  };

  useEffect(() => {
    if (data) {
      setNewPoll({ nome: data[0].nome_sondagem });
    }
  }, [data]);

  const editPoll = () => {
    const id_sítio = data[0].Sítio_id_sítio;
    axios
      .put(`/api/polls/${userID}/${id_sítio}/${id}`, {
        nome: newPoll.nome.trim(),
        intervenção: intervention,
      })
      .then((response) => {
        //   toast
        const { msg, error } = response.data;
        CustomToast(error, msg);

        if (!error) {
          navigate(`/sondagens/${id}`, { state: { id: intervention } });
        }
      })
      .catch((error) => console.log(error));
  };

  if (error) return <h1>Erro</h1>;
  if (!data)
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );

  if (!place) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <div className="container">
      <div className="title-container">
        <h1>Editar Sondagem</h1>
        <FontAwesomeIcon
          className="plus-icon"
          icon="arrow-left"
          size="lg"
          onClick={backToIntervention}
        />
      </div>
      <div className="show-poll-container">
        <div className="name border flex">
          <h3 className="ue-h3">Nome:</h3>
          <input
            className="ue-input"
            type="text"
            placeholder="Novo nome"
            value={newPoll.nome}
            onChange={(e) => setNewPoll({ nome: e.target.value })}
          />
        </div>
        <div className="place border flex">
          <h3 className="ue-h3">Sítio:</h3>
          <h3 style={{ marginLeft: "10px" }}> {place[0].sítio} </h3>
        </div>
      </div>
      <button className="create-ue-btn" onClick={editPoll}>
        Guardar
      </button>
    </div>
  );
};

export default EditPollPage;
