import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import InputField from "../../customs/Input";

import { checkString } from "../validations";

import useSWR from "swr";
import { axios } from "../../../api/axios";

const Matrix = (props) => {
  const { setSedimentary } = props;
  const { data: matrix, mutate } = useSWR("/api/ue/sedimentary/matrix");
  const [other, setOther] = useState("");
  const [newMatrix, setNewMatrix] = useState({ matriz: "" });

  const addMatrix = () => {
    if (!checkString(newMatrix.matriz)) {
      setOther("");
      return;
    }

    axios
      .post("/api/ue/sedimentary/matrix", newMatrix)
      .then(() => {
        setNewMatrix({ matriz: "" });
        setOther("");
        mutate();
      })
      .catch((error) => console.log(error));
  };

  const onSelectType = (selectedList, selectedItem) => {
    setOther(selectedItem.matriz);
    setSedimentary((prev) => ({ ...prev, matriz: selectedItem.id_matriz }));
  };

  const handleChange = (e) => {
    setNewMatrix({ matriz: e.target.value });
  };

  useEffect(() => {
    if (matrix) {
      const others = {
        id_matriz: 9999,
        matriz: "Outro",
      };

      const index = matrix.findIndex(
        (object) => object.id_matriz === others.id_matriz
      );

      if (index === -1) {
        matrix.push(others);
      }
    }
  }, [matrix]);

  if (!matrix) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return (
    <>
      <h3 className="ue-h3">Matriz:</h3>
      <div className="others">
        <Multiselect
          options={matrix}
          selectedValues={matrix.selectedValue}
          singleSelect
          onSelect={onSelectType}
          hidePlaceholder
          displayValue="matriz"
          placeholder="matriz"
          avoidHighlightFirstOption
          emptyRecordMsg="não foram encontradas matrizes"
        />
        <InputField
          other={other}
          value={newMatrix.matriz}
          handleChange={handleChange}
          add={addMatrix}
        />
      </div>
    </>
  );
};

export default Matrix;
