import React from "react";

const ViewInterface = (props) => {
  const { data } = props;

  return (
    <div className="interface-container" style={{ marginTop: "30px" }}>
      <div className="description border flex">
        <h3 style={{ wordBreak: "break-all" }}>Descrição: {data.descrição} </h3>
      </div>
      <div className="interpretation border flex">
        <h3 style={{ wordBreak: "break-all" }}>
          Interpretação: {data.interpretação}
        </h3>
      </div>
      <div className="cronology border flex">
        <h3 className="ue-h3">Cronologia: {data.cronologia} </h3>
      </div>
    </div>
  );
};

export default ViewInterface;
