import React from "react";

import { lithicStyles } from "../../../pages/Report/Styles";

import { View, Text } from "@react-pdf/renderer";

const LithicReport = (props) => {
  const { report } = props;

  return (
    <>
      <View style={lithicStyles.firstContainer} wrap={false}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Nº UE: {report.num_UE}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ fontFamily: "Courier-Bold" }}>
            Material: {report.material}
          </Text>
        </View>
      </View>
      <Text style={lithicStyles.margin}>Descrição: {report.descrição}</Text>
      <Text style={lithicStyles.margin}>
        Nº fragmentos: {report.fragmentos}
      </Text>
      <View style={lithicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Tipologia: {report.tipologia}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Material: {report.material_litico} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Cronologia: {report.cronologia} </Text>
        </View>
      </View>
      <View style={lithicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>Comprimento: {report.comprimento}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Largura: {report.largura} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Espessura: {report.espessura} </Text>
        </View>
      </View>
      <View style={lithicStyles.container}>
        <View style={{ flex: 1 }}>
          <Text>X: {report.x}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Y: {report.y} </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text>Z: {report.z} </Text>
        </View>
      </View>
    </>
  );
};

export default LithicReport;
