import React, { useState, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";

import { axios } from "../../../../api/axios";

const EditOverlapped = (props) => {
  const { data, uesPoll, setNewUE } = props;

  const onSelectOverlapped = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setNewUE((prev) => ({ ...prev, sobreposta: teste }));
  };

  const onRemoveOverlapped = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setNewUE((prev) => ({ ...prev, sobreposta: teste }));
  };

  const final = () => {
    const final = uesPoll.filter((object) => {
      if (data.sobreposta.includes(object.id_UE)) {
        return [object];
      }
    });

    return final;
  };

  return (
    <>
      <h3 className="ue-h3">Sobreposta:</h3>
      <Multiselect
        className="matrix-input"
        options={uesPoll}
        selectedValues={final()}
        showArrow
        onSelect={onSelectOverlapped}
        onRemove={onRemoveOverlapped}
        hidePlaceholder
        displayValue="num_UE"
        placeholder="UE"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas ues"
      />
    </>
  );
};

export default EditOverlapped;
