import React from "react";

import Multiselect from "multiselect-react-dropdown";

const EditPull = (props) => {
  const { data, uesPoll, setNewUE } = props;

  const onSelectPull = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setNewUE((prev) => ({ ...prev, encosta: teste }));
  };

  const onRemovePull = (selectedList, selectedItem) => {
    const teste = selectedList.map(({ id_UE }) => id_UE);
    setNewUE((prev) => ({ ...prev, encosta: teste }));
  };

  const final = () => {
    const final = uesPoll.filter((object) => {
      if (data.encosta.includes(object.id_UE)) {
        return [object];
      }
    });
    return final;
  };

  return (
    <>
      <h3 className="ue-h3">Encosta:</h3>
      <Multiselect
        className="matrix-input"
        options={uesPoll}
        selectedValues={final()}
        showArrow
        onSelect={onSelectPull}
        onRemove={onRemovePull}
        hidePlaceholder
        displayValue="num_UE"
        placeholder="UE"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontradas ues"
      />
    </>
  );
};

export default EditPull;
