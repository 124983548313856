import React, { useMemo, useContext, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import Multiselect from "multiselect-react-dropdown";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableContext } from "../../../context/TableContext";
import { axios } from "../../../api/axios";
import { Confirm } from "react-st-modal";
import { CustomToast } from "../Toast/Toast";

import { Context } from "../../../context/AuthContext";

import Filter from "./Filter";
import Pagination from "./Pagination";
import MaterialStatistic from "../../Material/MaterialStatistic";

import "./Table.css";

const state = [
  {
    name: "Materiais por Tipo",
    id: 1,
  },
  {
    name: "Cronologias por UE",
    id: 2,
  },
  {
    name: "Cerâmicas - Forma por UE",
    id: 3,
  },
];

const CustomTable = ({ columns, data }) => {
  const tableData = useMemo(() => [...data], [data]);
  const tableColumms = useMemo(() => [...columns], [columns]);
  const { view, deletePath, table, id, intervention, interventionID, mutate } =
    useContext(TableContext);
  const { role, id: userID } = useContext(Context);
  const [statistic, setStatistic] = useState(0);

  const deleteRecord = async (id) => {
    const isConfirm = await Confirm(
      "",
      "Tem a certeza que deseja eliminar esta entrada?"
    );

    if (isConfirm) {
      // apagar entrada na base de dados
      axios
        .delete(`/api/${deletePath}/${id}`)
        .then((response) => {
          // toast
          const { msg, error } = response.data;
          CustomToast(error, msg);

          setTimeout(() => {
            mutate();
          }, 1000);
        })
        .catch((error) => console.log(error));
    }
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Operações",
        Header: "Operações",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
            }}
          >
            <Link
              to={{
                pathname: `${view}/${row.values.id}`,
              }}
              state={{
                id: id,
                intervention: intervention,
                table: table,
                interventionID: interventionID,
              }}
            >
              <FontAwesomeIcon
                icon="binoculars"
                size="2x"
                className="TableButton"
              />
            </Link>
            {role === "admin" ? (
              <Link
                to={{
                  pathname: `${view}/${row.values.id}/editar`,
                }}
                state={{
                  id: id,
                  intervention: intervention,
                  table: table,
                  id_sondagem: row.values.id_sondagem,
                  id_intervenção: row.values.id_intervenção,
                  interventionID: interventionID,
                }}
              >
                <FontAwesomeIcon
                  icon="edit"
                  size="2x"
                  className="TableButton"
                />
              </Link>
            ) : (
              row.values.id_user === userID && (
                <Link
                  to={{
                    pathname: `${view}/${row.values.id}/editar`,
                  }}
                  state={{
                    id: id,
                    intervention: intervention,
                    table: table,
                    id_sondagem: row.values.id_sondagem,
                    id_intervenção: row.values.id_intervenção,
                    interventionID: interventionID,
                  }}
                >
                  <FontAwesomeIcon
                    icon="edit"
                    size="2x"
                    className="TableButton"
                  />
                </Link>
              )
            )}

            {role === "admin" && (
              <FontAwesomeIcon
                style={{
                  cursor: "pointer",
                }}
                icon="trash-alt"
                size="2x"
                className="TableButton"
                onClick={() => deleteRecord(row.values.id)}
              />
            )}

            {table === "intervention" && (
              <>
                <Link
                  to={{
                    pathname: `${view}/relatório/${row.values.id}`,
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                    }}
                    icon="book-open"
                    size="2x"
                    className="TableButton"
                  />
                </Link>
                <Link
                  to={{
                    pathname: `${view}/relatório/materiais/${row.values.id}`,
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                    }}
                    icon="bookmark"
                    size="2x"
                    className="TableButton"
                  />
                </Link>
              </>
            )}

            {table === "poll" && (
              <FontAwesomeIcon
                style={{
                  cursor: "pointer",
                }}
                icon="book-open"
                size="2x"
                className="TableButton"
                onClick={() => exportUserInfo(row.values.id)}
              />
            )}
          </div>
        ),
      },
    ]);
  };

  const exportUserInfo = (id) => {
    axios
      .get(`/api/polls/${id}/ues`)
      .then((response) => {
        const { acrónimo, nome_sondagem, reducer } = response.data;
        // const fileData = reducer;
        const blob = new Blob([reducer], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `${acrónimo}_${nome_sondagem}.lst`;
        link.href = url;
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const tableInstance = useTable(
    {
      columns: tableColumms,
      data: tableData,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns:
          role !== "admin"
            ? ["id", "id_user", "id_sondagem", "id_intervenção", "username"]
            : ["id", "id_user", "id_sondagem", "id_intervenção"],
      },
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize },
  } = tableInstance;

  const onSelectType = (selectedList, selectedItem) => {
    setStatistic(selectedItem.id);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Filter filter={globalFilter} setFilter={setGlobalFilter} />
        {table === "materials" && (
          <div style={{ marginLeft: "auto", marginRight: "0" }}>
            <Multiselect
              className="editMultiselect"
              options={state}
              singleSelect
              showArrow
              onSelect={onSelectType}
              hidePlaceholder
              placeholder="Estatística"
              displayValue="name"
              avoidHighlightFirstOption
            />
          </div>
        )}
      </div>
      <MaterialStatistic statistic={statistic} />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? "👇" : "☝️") : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* paginação */}
      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default CustomTable;
