import Axios from "axios";

const BASE_URL = {
  localhost: "http://localhost:3001",
  railway: "https://arqueologiaserver-production.up.railway.app/",
  render: "https://arqueologia-server.onrender.com/",
};

export const axios = Axios.create({
  baseURL: BASE_URL?.render,
  headers: {
    Auth: "André Lopes",
    "Content-type": "application/json",
  },
});
