import React from "react";

import Multiselect from "multiselect-react-dropdown";
import { CircularProgress } from "@material-ui/core";

import useSWR from "swr";

const EditTypes = (props) => {
  const { data, setNewUE } = props;
  const { data: types } = useSWR("/api/ue/constructive/types");

  const onSelectType = (selectedList, selectedItem) => {
    setNewUE((prev) => ({ ...prev, tipo: selectedItem.id_tipo }));
  };

  if (!types) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const typesValue =
    types &&
    types.map((value) => {
      return [
        {
          id_tipo: value.id_tipo,
          tipo: value.tipo,
        },
      ];
    });

  const types_id = typesValue.findIndex((obj) => obj[0].id_tipo === data.tipo);

  return (
    <>
      <h3 className="ue-h3">Tipo:</h3>
      <Multiselect
        options={types}
        selectedValues={typesValue[types_id]}
        singleSelect
        showArrow
        onSelect={onSelectType}
        hidePlaceholder
        displayValue="tipo"
        placeholder="tipo"
        avoidHighlightFirstOption
        emptyRecordMsg="não foram encontrados tipos"
      />
    </>
  );
};

export default EditTypes;
