import React from "react";

import { CircularProgress } from "@material-ui/core";

import { View, Text } from "@react-pdf/renderer";

import { table } from "../../../pages/Report/Styles";

import {
  Table,
  TableCell,
  TableHeader,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

const CeramicsFormByUE = (props) => {
  const { data } = props;

  if (!data) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  const header = Object.keys(data[0]);

  return (
    <View wrap={false}>
      <Text
        style={{
          textAlign: "center",
          fontFamily: "Courier-Bold",
          fontSize: "13px",
        }}
      >
        Cerâmicas - Forma por UE
      </Text>
      <View style={{ marginTop: 20 }}></View>
      <Table data={data}>
        <TableHeader textAlign="center">
          {header.map((header) => (
            <TableCell key={header} style={table.cell}>
              {header}
            </TableCell>
          ))}
        </TableHeader>
        <TableBody>
          {header.map((data) => (
            <DataTableCell
              key={data}
              style={table.normalCell}
              getContent={(r) => r[data]}
            />
          ))}
        </TableBody>
      </Table>
    </View>
  );
};

export default CeramicsFormByUE;
