import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../context/AuthContext";

import { CustomToast } from "../customs/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../api/axios";
import useSWR from "swr";

import "./Profile.css";

// informação útil acerca do utilizador -> estatísticas
const Profile = () => {
  const { id } = useContext(Context);
  const { data: user, mutate } = useSWR(() => `/api/profile/${id}`);
  const [profile, setProfile] = useState({ username: "", password: "" });
  const [check, setCheck] = useState(false);

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfile = () => {
    axios
      .put(`/api/profile/${id}`, profile)
      .then((response) => {
        const { msg, error } = response.data;
        CustomToast(error, msg);
        mutate();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (user) {
      setProfile((prev) => ({ ...prev, username: user.username }));
    }
  }, [user]);

  return (
    <div className="container">
      <h1 style={{ marginTop: "80px" }}>Perfil de {user?.username} </h1>

      <div className="profile-container">
        <div className="username flex border">
          <h3 style={{ textIndent: "10px" }}>nome: </h3>
          <input
            style={{ width: "300px", marginLeft: "10px", border: "none" }}
            type="text"
            name="username"
            value={profile.username || ""}
            placeholder="novo username"
            onChange={handleChange}
          />
        </div>
        <div
          className="password flex border"
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <h3 className="ue-h3">password: </h3>
          <input
            className="ue-input"
            style={{ width: "300px", marginLeft: "10px", padding: "15px" }}
            type={check ? "text" : "password"}
            name="password"
            placeholder="nova password"
            onChange={handleChange}
          />
          <FontAwesomeIcon
            style={{ cursor: "pointer", color: "#b7410e", marginLeft: "10px" }}
            onClick={() => setCheck((prev) => !prev)}
            icon={check ? "eye-slash" : "eye"}
            size="lg"
          />
        </div>
      </div>
      <button className="create-ue-btn" onClick={updateProfile}>
        Gravar
      </button>
    </div>
  );
};

export default Profile;
