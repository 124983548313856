import React from "react";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import StatisticsTable from "../customs/Table/StatisticsTable";

import useSWR from "swr";

const header = [
  {
    Header: "UE",
    accessor: "num_UE",
  },
  {
    Header: "Cerâmica",
    accessor: "cerâmica",
  },
  {
    Header: "Lítico",
    accessor: "litico",
  },
  {
    Header: "Metal",
    accessor: "metal",
  },
  {
    Header: "Moeda",
    accessor: "moeda",
  },
  {
    Header: "Objeto Diverso",
    accessor: "diverso",
  },
  {
    Header: "Vidro",
    accessor: "vidro",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "%",
    accessor: "percentagem",
  },
];

const MaterialsByType = () => {
  const { id } = useParams();
  const { data: materialsByType } = useSWR(
    () => `/api/report/intervention/${id}/materials/type`
  );

  if (!materialsByType) {
    return (
      <CircularProgress
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "15vh",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  return <StatisticsTable columns={header} data={materialsByType} />;
};

export default MaterialsByType;
