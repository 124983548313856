import React from "react";

import Multiselect from "multiselect-react-dropdown";

const option = [
  {
    id: 1,
    estado: "Sim",
  },
  {
    id: 2,
    estado: "Não",
  },
];

const Painted = (props) => {
  const { setCeramics } = props;

  const onSelectPainted = (selectedList, selectedItem) => {
    setCeramics((prev) => ({ ...prev, pintada: selectedItem.estado }));
  };

  return (
    <>
      <h3 className="ue-h3">Pintada:</h3>
      <Multiselect
        options={option}
        singleSelect
        onSelect={onSelectPainted}
        hidePlaceholder
        displayValue="estado"
        placeholder="Pintada"
        avoidHighlightFirstOption
      />
    </>
  );
};

export default Painted;
