import React from "react";

const ViewMetal = (props) => {
  const { data } = props;

  return (
    <div className="metal-container">
      <div className="description flex border">
        <h3 className="wordBreak">Descrição: {data.descrição} </h3>
      </div>
      <div className="fragments flex border">
        <h3 className="ue-h3">Nº fragmentos: {data.fragmentos} </h3>
      </div>
      <div className="material flex border">
        <h3 className="ue-h3">Matéria-prima: {data.matéria} </h3>
      </div>
      <div className="form flex border">
        <h3 className="ue-h3">Forma: {data.forma} </h3>
      </div>
      <div className="state flex border">
        <h3 className="ue-h3">Estado: {data.conservação} </h3>
      </div>
      <div className="function flex border">
        <h3 className="ue-h3">Função: {data.função} </h3>
      </div>
      <div className="typology flex border">
        <h3 className="ue-h3">Tipologia: {data.tipologia} </h3>
      </div>
      <div className="coordinates flex border">
        <h3 className="ue-h3">Coordenadas:</h3>
      </div>
      <div className="x flex border">
        <h3 className="ue-h3">X: {data.x} </h3>
      </div>
      <div className="y flex border">
        <h3 className="ue-h3">Y: {data.y} </h3>
      </div>
      <div className="z flex border">
        <h3 className="ue-h3">Z: {data.z} </h3>
      </div>
    </div>
  );
};

export default ViewMetal;
